<template>
  <v-col class="pa-2 bookings">
    <v-row justify="space-between" class="pa-2 bookings-header">
      <div>
        <g-button
          :label="showSearch ? 'hide' : 'show'"
          :icon="showSearch ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          @onclick="toggleSearch"
        />
        <BookingExportButton :searchQuery="dbQuery" />
      </div>

      <d-header label="Bookings" />

      <div>
        <g-button
          v-show="showSupplierEmailButton"
          @onclick="$router.push({ name: 'booking_supplier_email' })"
          label="Email Supplier"
          icon="send"
          type="primary"
          class="mx-2"
        />
        <g-button
          v-show="!showSupplierEmailButton"
          @onclick="$router.push({ name: 'booking_search_email' })"
          label="Email Suppliers"
          icon="send"
          type="primary"
          class="mx-2"
        />
        <add-button @click="createNew" />
      </div>
    </v-row>

    <BookingSearch v-show="showSearch" @search="updateQuery" />

    <BookingTable
      :grouped="dbQuery.byStatus"
      :query="dbQuery"
      class="mx-2 table"
      ref="editDBwrapper"
      :height="dbHeight"
    />
  </v-col>
</template>

<script>
import { BookingSearch, BookingTable, BookingExportButton } from "@/modules/bookings";

export default {
  components: {
    BookingSearch,
    BookingTable,
    BookingExportButton,
  },
  data() {
    return {
      showSearch: true,
      dbQuery: {},

      dbHeight: "0px",
    };
  },
  computed: {
    showSupplierEmailButton() {
      return this.$store.getters["BookingEmailStore/statusSearch"].supplier_id;
    },
  },
  methods: {
    createNew() {
      this.$router.push({
        name: "bookings_new",
      });
    },
    updateQuery(query) {
      this.dbQuery = { ...query };
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
      this.$nextTick().then(function (res) {
        let OFFSET = 50;
        if (res.showSearch) OFFSET += 175;
        if (!res.$refs.editDBwrapper) return;
        res.dbHeight = res.$refs.editDBwrapper.$el.clientHeight - OFFSET + "px";
      });
    },
  },
  mounted() {
    const env = this.$store.getters["AccountStore/environment"];
    this.$store.dispatch("BookingStore/setBooking", { env });
    this.$nextTick().then(function (res) {
      let OFFSET = 50;
      if (res.showSearch) OFFSET += 175;
      if (!res.$refs.editDBwrapper) return;
      res.dbHeight = res.$refs.editDBwrapper.$el.clientHeight + "px";
    });
  },
};
</script>

<style lang="scss">
.bookings {
  height: 100%;
  display: flex;
  flex-direction: column;

  .bookings-header {
    max-height: 50px;
  }

  .booking-list {
    max-height: 400px;
    overflow: auto;

    &.longlist {
      max-height: 500px;
    }
  }

  .table {
    flex: 1;
  }
}

.db-card:hover {
  cursor: pointer;
}
</style>
