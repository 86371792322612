<template>
  <div class="d-flex align-center">
    <v-btn v-if="paid === 3" color="primary" small depressed>Deposit</v-btn>
    <v-btn v-else-if="paid === 2" color="warning" small depressed>Part Paid</v-btn>
    <v-btn v-else-if="paid === 1" color="green" small depressed>Paid</v-btn>
    <v-btn v-else text small>Not Paid</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    paidAmount: [Number, String],
    total: [Number, String],
    isdeposit: Boolean,
    isCustomerPaymentPaid: Boolean,
  },
  computed: {
    paid() {
      const paid = Number(this.paidAmount);
      const total = Number(this.total);
      if (this.isCustomerPaymentPaid) return 1;
      if (paid === 0) return 0;
      if (this.isdeposit || (total === 0 && paid > 0)) return 3;
      if (paid >= total) return 1;
      if (paid > 0 && paid < total) return 2;
      return 0;
    },
  },
};
</script>
