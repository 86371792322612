<template>
  <div>
    <tiptap-vuetify
      id="TextEditor"
      ref="tiptap"
      class="richtext"
      :class="maxheight"
      :value="value"
      @keydown="keydown"
      @input="(v) => $emit('input', v)"
      :extensions="extensions"
      :editor-properties="editorprops"
      placeholder="Text..."
    />
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History /*Image*/,
} from "tiptap-vuetify";

export default {
  props: {
    value: String,
    maxheight: String,
  },
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      anchorTrack: 0,
      extensions: [
        History,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Italic,
        Underline,
        Link,
        // Image,		-- Emails fail to display base64 images
        Blockquote,
        ListItem,
        BulletList,
        OrderedList,
        HorizontalRule,
        HardBreak,
      ],
      editorprops: {
        onTransaction: ({ state }) => {
          this.anchorTrack = state.selection.anchor;
        },
      },
    };
  },
  methods: {
    keydown(e) {
      if (e.code !== "KeyV") return;
      if (!this.value.match(/\r?\n/)) return;
      e.stopPropagation();
      e.preventDefault();

      // Replace new line characters with <br>
      // Bug fix as they will all be forced onto one line
      const pastedData = ""; //clipboard.readText()
      const fixedInput = pastedData.split(/\r?\n/).join("<br>");
      const value = this.value || "";
      this.$emit("input", value.slice(0, this.anchorTrack) + fixedInput + value.slice(this.anchorTrack));
    },
    handleNewLineCase(v) {
      this.$emit("input", v);
    },
  },
  // mounted() {
  //    this.editor = new Editor({
  //      content: '<p>I’m running Tiptap with Vue.js. 🎉</p>',
  //      extensions: [
  //        StarterKit,
  //      ],
  //    })
  //  },

  //  beforeDestroy() {
  //    this.editor.destroy()
  //  }
};
</script>

<style lang="scss">
.richtext {
  .v-card {
    box-shadow: none;
    border: 1px solid lightgrey !important;
  }

  .v-btn--icon.v-size--small {
    height: 20px;
    width: 20px;

    .v-icon {
      height: 20px;
      font-size: 20px;
      width: 20px;
    }
  }

  &.tiptap-vuetify-editor .ProseMirror {
    margin: 10px !important;
  }

  .tiptap-vuetify-editor__content {
    height: 300px;
    font-size: 11pt;
    font-family: "Calibri", sans-serif;
  }

  &.short .tiptap-vuetify-editor__content {
    height: 100px;
  }
}
</style>
