<template>
  <v-row justify="space-between">
    <v-col class="subtitle-2" cols="3" align-self="center">Paid</v-col>
    <v-col class="d-flex justify-end" cols="5">
      <input type="checkbox" :disabled="disabled" :checked="value" @input="$emit('input', $event.target.checked)" />
    </v-col>
  </v-row>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    value: Boolean,
    disabled: Boolean,
  },
};
</script>
