<template>
  <v-col class="pa-0">
    <v-snackbar dense top :timeout="offlineTime" color="warning" :value="!canSave.pass">
      <div>
        <v-icon left>warning</v-icon>
        <span>{{ canSave.cannotSaveMessage }}</span>
      </div>
    </v-snackbar>

    <InformationHeader
      :admin="isAdmin"
      :supplierId="supplierId || $route.params.id"
      :ondelete="deleteSupplier"
      :onsave="saveInformation"
      :canSave="canSave.pass"
    />

    <v-row>
      <v-col cols="6" class="d-flex flex-column pa-0">
        <GeneralInfo :supplierType="supplierType" :supplierId="supplierId" />
        <AddressInfo :supplierType="supplierType" style="flex-grow: 1" />
      </v-col>

      <v-col cols="6" class="d-flex flex-column pa-0">
        <ContactInfo :supplierType="supplierType" />

        <SocialInfo v-if="supplierType === 'TOUR_GUIDE'" />
        <MetaInfoTourGuide v-if="supplierType === 'TOUR_GUIDE'" style="flex-grow: 1" />

        <MetaInfo v-else :supplierType="supplierType" style="flex-grow: 1" />
      </v-col>
    </v-row>

    <ExtraInfo fieldName="comments" :supplierType="supplierType" />
    <ExtraInfo fieldName="social_responsibilities" :supplierType="supplierType" />
  </v-col>
</template>

<script>
import {
  InformationHeader,
  GeneralInfo,
  AddressInfo,
  ContactInfo,
  MetaInfo,
  MetaInfoTourGuide,
  ExtraInfo,
  SocialInfo,
} from "@/modules/suppliers.information";

import OPTION_LIST_CONFIG from "@/config/OPTION_LIST_CONFIG";

export default {
  components: {
    InformationHeader,
    GeneralInfo,
    AddressInfo,
    ContactInfo,
    MetaInfo,
    MetaInfoTourGuide,
    ExtraInfo,
    SocialInfo,
  },
  data() {
    return {
      offlineTime: -1,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
    supplierType() {
      return this.$store.getters["SupplierStore/type"];
    },
    supplierId() {
      return this.$store.getters["SupplierStore/id"];
    },
    canSave() {
      // Avoids showing message while page is loading
      if (!this.get("type")) return true;

      const name = this.get("name");
      const country = this.get("country");
      const region = this.get("region");
      const subjur = this.get("subjur");
      const city = this.get("city");

      let pass = true;
      let message = [];
      const fail = (name) => {
        pass = false;
        message.push(name);
      };
      if (!name) fail("Name");
      if (!country) fail("Country");

      if (!region && this.supplierType !== "TOUR_GUIDE") {
        // Region required for non-tour guides
        fail("Region");
        if (!subjur) fail("State/Prefecture");
        if (!city) fail("City");
      } else if (region && region !== "NAT") {
        // Region defined, but need subjur and city
        if (!subjur) fail("State/Prefecture");
        if (!city) fail("City");
      }

      return {
        cannotSaveMessage: `Save disabled. Missing: ${message.join(", ")}`,
        pass: !!pass,
      };
    },
  },
  methods: {
    get(key) {
      return this.$store.getters["SupplierStore/" + key];
    },
    deleteSupplier() {
      const name = this.$store.getters["SupplierStore/name"];
      const type = this.supplierType;
      return this.$store
        .dispatch("SupplierStore/deleteSupplier")
        .then((v) => {
          this.$root.$success(`Removed: ${name}`);
          this.$router.push({ name: "database_view", params: { view: "suppliers", sub: type } });
        })
        .catch(this.$root.$error);
    },
    saveInformation() {
      if (!this.canSave.pass) return Promise.reject();

      const name = this.$store.getters["SupplierStore/name"];
      const needsPidUpdate = this.$store.getters["SupplierStore/needsPidChange"];

      return this.$store
        .dispatch("SupplierStore/updateSupplier")
        .then((v) => this.updatePid(needsPidUpdate))
        .then((v) => {
          this.$root.$success(`Updated: ${name}`);
        })
        .catch(this.$root.$error);
    },

    updatePid(updatePid) {
      if (!updatePid) return Promise.resolve();

      // Update supplier pid
      this.$root.$loading.open("Resetting supplier id");
      return this.$store
        .dispatch("SupplierStore/resetPid")
        .then(() => this.$store.dispatch("SupplierStore/getSupplier", this.supplierId))
        .then(() => {
          this.$root.$loading.end();
        });
    },
  },
  mounted() {
    Object.keys(OPTION_LIST_CONFIG).forEach((key) => this.$store.dispatch("ListStore/getOptionList", key));
  },
  beforeDestroy() {
    // Save on tab change
    if (!this.canSave.pass) return;
    if (this.$store.getters["SupplierStore/hasChanges"]) {
      this.saveInformation();
    }
  },
};
</script>
