<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        small
        v-on="on"
        class="report-btn"
        :color="commisionReceived ? 'blue' : onlyPartReceived ? 'orange' : 'grey'"
      >
        <v-icon v-if="commisionReceived || onlyPartReceived" small>check_circle</v-icon>
        <v-icon v-else small>check_circle_outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="d-flex flex-column align-start">
        <div>Received Commission History</div>
        <div class="subtitle-2">{{ supplierName }}</div>
      </v-card-title>
      <v-card-text class="black--text">
        <v-row justify="center">
          <v-btn-toggle v-model="receivedType" mandatory>
            <v-btn value="FULL" small> Full </v-btn>
            <v-btn value="PARTIAL" small> Partial </v-btn>
          </v-btn-toggle>
        </v-row>

        <TotalReceivedAndNotRow
          :commission="commission"
          :commissionTotal="commissionTotal"
          :customCommissionAmount="customCommissionAmount"
          :commisionReceivedTotal="commisionReceivedTotal"
          :remainingTotal="remainingTotal"
          :commissionReceivedHistory="commissionReceivedHistory"
        />

        <v-divider />

        <Payment
          v-if="receivedType === 'PARTIAL'"
          altlabel="Amount to add"
          :value="displayNumber"
          @input="(v) => (customValue = v)"
          :remainingTotal="remainingTotal"
        />

        <PaymentDateRow v-model="commissionDate" />
      </v-card-text>

      <v-card-actions>
        <g-button @onclick="dialog = false" label="Close" />
        <div class="flex-grow-1"></div>
        <g-button type="primary" @onclick="addCommission" label="Add payment" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "@/services/format";
import money from "@/services/money";
import Payment from "../../paymentTypes/Payment";
import PaymentDateRow from "../../paymentTypes/_PaymentDateRow";
import TotalReceivedAndNotRow from "./_TotalReceivedAndNotRow";
export default {
  props: {
    external: Boolean,
    bookingId: String,
    supplierId: String,
    supplierName: String,

    commission: [Number, Boolean],
    commissionTotal: String,
    customCommissionAmount: [Number, String],

    commissionReceivedHistory: Array,
  },
  components: {
    Payment,
    PaymentDateRow,
    TotalReceivedAndNotRow,
  },
  data() {
    return {
      dialog: false,
      customValue: null,
      receivedType: "FULL",
      commissionDate: format.convertDate(new Date()),
    };
  },
  computed: {
    displayNumber() {
      if (this.customValue === 0) return 0;
      return money.stripFormatting(this.customValue || this.customCommissionAmount || this.commissionTotal);
    },
    commisionReceived() {
      return (this.commissionReceivedHistory || []).find((v) => v.received_type === "FULL");
    },
    onlyPartReceived() {
      return (this.commissionReceivedHistory || []).find((v) => v.received_type === "PARTIAL");
    },
    commisionReceivedTotal() {
      return (this.commissionReceivedHistory || []).reduce((total, item) => {
        return total + money.stripFormatting(item.total_received);
      }, 0);
    },
    remainingTotal() {
      return money.stripFormatting(this.customCommissionAmount || this.commissionTotal) - this.commisionReceivedTotal;
    },
  },
  methods: {
    toggleCommission() {
      this.$store.dispatch(`FinanceReportStore/addCommissionReceived`, {
        skipCommit: this.external,
        booking_id: this.bookingId,
        supplier_id: this.supplierId,
      });
    },

    addCommission() {
      this.$store
        .dispatch(`FinanceReportStore/addCommissionReceived`, {
          skipCommit: this.external,
          booking_id: this.bookingId,
          supplier_id: this.supplierId,
          data: {
            total_received: this.receivedType === "FULL" ? this.remainingTotal : this.displayNumber,
            received_type: this.receivedType,
            set_date: this.commissionDate,
          },
        })
        .then((nurow) => {
          this.$emit("add-row", nurow);
          this.dialog = false;
        });
    },
    // update(){
    // 	this.$store.dispatch('FinanceCompileStore/setCustomCommission', {
    // 		skipCommit: this.external,
    // 		booking_id: this.bookingId,
    // 		supplier_id: this.supplierId,
    // 		type_as: 'HOTEL',
    // 		customCommissionId: this.customCommissionId,
    // 		data: {
    // 			custom_amount: this.displayNumber
    // 		},
    // 		cache: {}
    // 	})
    // 	.then(custom => {
    // 		this.dialog = false;
    // 		this.$emit('sync-commission', { type: 'UPDATE', data: custom })
    // 	})
    // },
    // remove(){
    // 	this.$store.dispatch('FinanceCompileStore/removeCustomCommission', {
    // 		skipCommit: this.external,
    // 		custom_commission_id: this.customCommissionId
    // 	})
    // 	.then(custom => {
    // 		this.dialog = false;
    // 		this.$emit('sync-commission', { type: 'DELETE', custom_commission_id: custom })
    // 	})

    // }
  },
};
</script>
