<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent width="90%" height="500px">
    <v-card class="supplier-select-wrapper">
      <v-card-title>
        <span>Select Product</span>

        <add-button @click="goToProductAdd" />

        <v-col class="d-flex align-center justify-end subtitle-1">
          <v-btn
            icon
            v-for="n in [1, 2]"
            :key="n"
            small
            :color="step > 1 ? 'primary' : ''"
            :outlined="step === n"
            :disabled="step < n"
            @click="step = n"
          >
            <span>{{ n }}</span>
          </v-btn>
        </v-col>
      </v-card-title>

      <ProductSelectView
        v-if="step === 1"
        :module="module"
        :productFilter="productFilter"
        :startDay="startDay"
        :editProduct="selectedItems"
        @cancel="$emit('input', false)"
        @select="selectProduct"
      />

      <div v-if="step === 2">
        <MetaConfigView
          :disabled="disabled"
          :type="module"
          :metaconfig="pendingMeta"
          :time="pendingTime"
          @set-time="(v) => (pendingTime = v)"
        />
        <ProductCustomPriceView
          :disabled="disabled"
          :type="module"
          :useDate="useDate"
          :bookingDayYear="bookingDayYear"
          :supplier="supplier"
          :products="selectedItems"
          :metaconfig="pendingMeta"
        />
        <ProductExtraView
          :disabled="disabled"
          :type="module"
          :bookingDayYear="bookingDayYear"
          :supplier="supplier"
          :products="selectedItems"
          :metaconfig="pendingMeta"
          :loading="loading"
          @cancel="$emit('input', false)"
          @revert="step = 1"
          @finish="continueProductSelect"
        />
      </div>
    </v-card>

    <NoTimeWarningDialog v-model="noTimeDialog" @next="finishProduct" />
  </v-dialog>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import ProductSelectView from "./select/ProductSelectView";
import ProductCustomPriceView from "./meta/ProductCustomPriceView";
import MetaConfigView from "./meta/MetaConfigView";
import ProductExtraView from "./extra/ProductExtraView";
import DateFormat from "@/services/format";
import NoTimeWarningDialog from "../../dialogs/_NoTimeWarningDialog";
import bookingsDaysUtils from "@/modules/bookings.days/components/utils";

export default {
  props: {
    mode: String,

    value: Boolean,
    disabled: Boolean,
    startDay: [String, Date],

    module: String,
    supplier: Object,
    productFilter: Object,

    // EDIT
    edit: Boolean,
    editBsSupplierId: String,
    editProduct: Object,
    editTime: String,
    editConfig: Object,

    // Nested
    nested: Boolean,
    nestedType: String,
    parentBookingSupplierId: String,

    // Replace
    replace: Boolean,
    replaceBsSupplierId: String,
    replaceProduct: Object,
    replaceBsMeta: Object,
  },
  components: {
    ProductSelectView,
    ProductCustomPriceView,
    MetaConfigView,
    ProductExtraView,

    NoTimeWarningDialog,
  },
  data() {
    return {
      dbHeight: "0px",
      useCustomDate: false,

      step: 1,
      noTimeDialog: false,
      loading: false,
      editMode: false,
      replaceMode: false,

      // Product Flosting
      selectedItems: {},
      pendingTime: null,
      pendingMeta: {},
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.step = 1;
        this.selectedItems = this.editProduct || {};
        this.pendingTime = this.editTime || null;
        this.pendingMeta = this.editConfig || {};
        this.editMode = !!this.editBsSupplierId;
        this.replaceMode = !!this.replaceBsSupplierId;

        if (this.editMode) this.step = 2;
      }
    },
  },
  computed: {
    bookingDayYear() {
      return moment(this.startDay).format("YYYY");
    },
    useDate() {
      return {
        from_month: moment(this.startDay).month() + 1,
        from_day: moment(this.startDay).date(),
      };
    },
  },
  methods: {
    goToProductAdd() {
      const route = this.$router.resolve({
        name: "suppliers_products_new",
        params: {
          id: this.supplier.id,
        },
      });
      window.open(route.href, "_blank");
    },
    selectProduct(data) {
      this.selectedItems = data.products;
      this.pendingMeta = this.editMode ? this.editConfig : this.replaceMode ? this.replaceBsMeta : data.config || {};
      this.step = 2;
    },
    continueProductSelect() {
      const needTime = ["EXCURSION", "TRANSPORT", "RESTAURANT"].includes(this.type);
      if (needTime && !this.pendingTime) {
        // If time isn't added, then need to warn
        this.noTimeDialog = true;
        return;
      }

      if (this.editMode || this.replaceMode) {
        // Get all key of previous products
        const previousProductkeys = this.editMode ? Object.keys(this.editProduct) : Object.keys(this.replaceProduct);
        const newProductkeys = Object.keys(this.selectedItems);

        if (previousProductkeys.length > 0 && newProductkeys.length > 0) {
          const firstPreviousProductkeys = previousProductkeys[0];
          const firstNewProductkeys = newProductkeys[0];

          if (firstPreviousProductkeys != firstNewProductkeys) {
            this.replaceKeysRecursive(this.pendingMeta, firstPreviousProductkeys, firstNewProductkeys);
          }
        }
      }

      this.finishProduct();
    },

    replaceKeysRecursive(obj, oldKey, newKey) {
      if (obj instanceof Object) {
        // Process keys at the current level
        for (let key in obj) {
          if (key === oldKey) {
            // Replace the key with the new key
            obj[newKey] = obj[key];
            delete obj[key]; // Remove the old key
          } else if (typeof obj[key] === "object") {
            // Recursively traverse nested objects
            this.replaceKeysRecursive(obj[key], oldKey, newKey);
          }
        }
      }
    },

    finishProduct() {
      this.loading = true;
      this._save({
        products: this.selectedItems,
        config: this.pendingMeta,
        ...(this.pendingTime && { time: this.pendingTime }),
      })
        .then((bsid) => {
          this.loading = false;
          this.$emit("select", bsid);
          this.$emit("input", false);
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },

    /*****
     * SAVE STUFF HERE
     * ****/
    _save(c) {
      // // Package and Create Booking/Quote stuff

      let TARGET_NAME = this.mode;
      if (this.editMode) TARGET_NAME += "_EDIT";
      if (this.replace) TARGET_NAME += "_REPLACE";

      let TARGET = {
        BOOKING: "BookingDayStore/createBookingSupplier",
        QUOTE: "QuoteStore/createQuoteSupplier",

        BOOKING_EDIT: "BookingDayStore/updateBookingSupplier",
        QUOTE_EDIT: "QuoteStore/updateBookingSupplier",

        BOOKING_REPLACE: "BookingDayStore/replaceBookingSupplier",
        QUOTE_REPLACE: "QuoteStore/replaceQuoteSupplier",
      }[TARGET_NAME];

      let CORE_ID = {
        BOOKING: {
          bookingId: this.$store.getters["BookingStore/booking_id"], // HACKY -- Because inconsistentcy
          booking_id: this.$store.getters["BookingStore/booking_id"],
        },
        QUOTE: {
          quote_id: this.$store.getters["QuoteStore/quote_id"],
        },
      }[this.mode];

      const s = this.supplier;
      const content = {
        // SUPPLIERS
        type_as: this.module,
        supplier_id: s.id,
        country: s.country,
        name: s.name,
        city: s.city,
        subcity: s.subcity,
        supplier_meta: s.meta,

        ...(this.nested && {
          nest_type: this.nestedType,
          // Add to existing (this will be null if new parent)
          ...(this.parentBookingSupplierId && { parent_bsid: this.parentBookingSupplierId }),
        }),

        // PRODUCTS
        product_year: this.bookingDayYear,
        products: c.products,
        status: 0,
        time: c.time,
        meta: _.pick(c.config, [
          "custom_prices",
          "custom_names",
          // Product counts
          "count",
          "count_lock",
          "confirmed_offcount",

          "use_dates",
          "technical_text",
          "room_num",
          "porterage",
          "parking",

          "has_custom_resortfee",
          "custom_resortfee_value",
          "has_custom_commission",
          "custom_commission_value",
          "custom_room_num",

          "exclude_suppliers",
          "quote_price",
        ]),
      };

      const isProductRateTypeGroup = bookingsDaysUtils.isProductRateTypeGroup(content.products);
      if (isProductRateTypeGroup) {
        // Find the index of the day that matches the startDay
        const dayIndex = this.$store.getters["BookingDayStore/days"].findIndex((day) => day.date === this.startDay);

        // Get the tour guides and transport for that day
        const tourguides = this.$store.getters["BookingDayStore/daySupplier"](dayIndex, "tour_guides");
        const transport = this.$store.getters["BookingDayStore/daySupplier"](dayIndex, "transport");

        // Combine the tourguides and transport arrays and map them to only include their ids
        const bsids = [...tourguides, ...transport].map((item) => item.id);

        // Initialize the 'exclude_suppliers' object if it doesn't exist
        content.meta["exclude_suppliers"] = content.meta["exclude_suppliers"] || {};

        // Initialize the key for the current day if it doesn't exist
        content.meta["exclude_suppliers"][this.startDay] = content.meta["exclude_suppliers"][this.startDay] || {};

        // Iterate over the supplier ids and set them to 'true' in the exclude_suppliers object
        bsids.forEach((id) => {
          content.meta["exclude_suppliers"][this.startDay][id] = true;
        });
      }

      if (this.editMode) {
        return this.$store.dispatch(TARGET, {
          ...CORE_ID,
          bookingSupplierId: this.editBsSupplierId,
          data: {
            content: _.pick(content, ["products", "product_year", "time", "meta"]),
          },
        });
      }

      return this.$store.dispatch(TARGET, {
        ...CORE_ID,
        ...(this.replace && { replaceBsSupplierId: this.replaceBsSupplierId }),
        type_as: this.module,
        dateList: [this.startDay],
        product_year: this.bookingDayYear,
        content,
      });
    },
  },
};
</script>
