<template>
  <a-card nopad class="mx-2">
    <v-tabs v-model="tab" class="elevation-0" :grow="true">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="item in tabs" :key="item.key">
        <span>{{ item.text }}</span>
        <v-icon small right color="primary" v-if="item.key === active">gps_fixed</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="general" class="mx-2">
        <GeneralSearch :groupListBlob="groupListBlob" @search="(v) => $emit('search', v)" />
      </v-tab-item>
      <v-tab-item key="status" class="mx-2">
        <StatusSearch
          :groupListBlob="groupListBlob"
          :supplierListBlob="supplierListBlob"
          @updateSupplierList="setSupplierList"
          @search="(v) => $emit('search', v)"
        />
      </v-tab-item>
    </v-tabs-items>
  </a-card>
</template>

<script>
import GeneralSearch from "./search/_GeneralSearch";
import StatusSearch from "./search/_StatusSearch";

export default {
  components: {
    StatusSearch,
    GeneralSearch,
  },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          text: "General",
          key: "general",
        },
        {
          text: "Status",
          key: "status",
        },
      ],

      groupListBlob: {},
      supplierListBlob: [],
    };
  },

  computed: {
    active() {
      const saved = this.$store.getters["BookingEmailStore/activeTab"];
      return saved;
    },
  },
  methods: {
    setSavedSearch() {
      if (!this.active) return;

      // Get saved tab (if exists)
      const savedTab = this.$store.getters[`BookingEmailStore/${this.active}Search`] || {};
      const index = this.tabs.findIndex((item) => this.active === item.key);
      this.tab = index;

      // Query existing search
      this.$emit("search", savedTab);
    },
    setSupplierList(options) {
      this.$store.dispatch("BookingStore/getSupplierListForBookings", options || {}).then((supplierListBlob) => {
        this.supplierListBlob = supplierListBlob
          .map((v) => {
            return {
              sortName: v.name,
              label: `${v.name} (${v.city || "Nationwide"}${v.subcity ? " / " + v.subcity : ""})`,
              value: v.supplier_id,
              bookingStatusList: v.bookingStatusList,
            };
          })
          .sort((a, b) => {
            if (a.sortName > b.sortName) return 1;
            if (a.sortName < b.sortName) return -1;
            return 0;
          });
      });
    },
  },
  mounted() {
    const hist = this.$routerHistory._history;
    const last = hist[hist.length - 1];
    if (last.includes("booking_search_email") || last.includes("suppliers")) {
      // If returning from email booking or click on supplier, then return
      this.setSavedSearch();
    } else {
      // Clear for all other routes
      // TODO: Check why a reset of the search is needed here
      // this.$store.dispatch("BookingEmailStore/resetSearch");
      // this.$emit("search", {});
      const savedTab = this.$store.getters[`BookingEmailStore/${this.active}Search`] || {};
      this.$emit("search", savedTab);
    }

    this.$store.dispatch("BookingStore/getBookingGroupList").then((groupListBlob) => {
      this.groupListBlob = groupListBlob;
    });

    this.setSupplierList();
  },
};
</script>
