<template>
  <div>
    <elwrapper label="Client" midtext>
      <div class="select-wrapper">
        <v-autocomplete
          dense
          outlined
          hide-details
          item-value="id"
          item-text="name"
          :items="rows"
          :value="value"
          @input="this.handleInput"
        ></v-autocomplete>
        <v-btn v-show="showClear" @click="$emit('input', null)" x-small icon class="select-clear">
          <v-icon>clear</v-icon>
        </v-btn>
      </div>
    </elwrapper>
  </div>
</template>

<script>
export default {
  props: {
    value: [Object, String],
    hideClear: Boolean,
  },
  data() {
    return {
      rows: [],
    };
  },
  computed: {
    showClear() {
      return !this.hideClear && this.value != null;
    },
  },
  methods: {
    handleInput(value) {
      const selectedRow = this.rows.find((row) => row.id === value) || null;
      this.$emit("input", selectedRow);
    },
    fetchClients() {
      this.$store
        .dispatch("ClientStore/getClientList", {})
        .then((rows) => (this.rows = rows))
        .catch((err) => {});
    },
  },
  mounted() {
    this.fetchClients();
  },
};
</script>
