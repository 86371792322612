<template>
  <v-row>
    <v-col align-self="center" class="grey lighten-2 px-2" style="max-width: 80px; height: 30px">
      <div class="caption font-weight-bold" style="line-height: 16px">INVOICE</div>
    </v-col>

    <v-col align-self="center" class="grey lighten-2" style="max-width: 120px">
      <div>
        <v-icon small left>event</v-icon>
        {{ displayDate }}
      </div>
    </v-col>

    <v-col
      align-self="center"
      class="grey lighten-2 d-flex justify-end align-center"
      style="max-width: 120px; height: 30px"
    >
      <div style="line-height: 16px">{{ displayClientTotal }}</div>
    </v-col>

    <v-col align-self="center" class="d-flex" style="max-width: 100px">
      <div>
        <span v-if="client_invoice.invoice_type === 'DEPOSIT'" class="primary white--text pa-1">(Deposit)</span>
        <span v-if="client_invoice.invoice_type === 'PARTIAL'" class="warning white--text pa-1">(Partial)</span>
        <span v-if="client_invoice.invoice_type === 'FULL'" class="green white--text pa-1">(Full)</span>
      </div>
      <v-btn x-small icon class="mr-2 report-btn" @click="$emit('toggle-invoice-history')">
        <v-icon small>{{ showInvoiceHistory ? "visibility" : "visibility_off" }}</v-icon>
      </v-btn>
      <v-btn icon x-small @click="$emit('open-invoice-dialog')" class="report-btn">
        <v-icon small>edit</v-icon>
      </v-btn>
    </v-col>

    <v-col align-self="center" class="d-flex justify-end" style="max-width: 100px">
      <span :class="isPaidColor">
        {{ displayPartPaidTotal }}
      </span>
    </v-col>

    <v-col align-self="center" class="d-flex" style="max-width: 50px">
      <div class="d-flex align-center">
        <v-btn x-small icon class="mr-2 report-btn" @click="$emit('toggle-history')">
          <v-icon small>{{ showHistory ? "visibility" : "visibility_off" }}</v-icon>
        </v-btn>

        <v-btn icon x-small class="mr-2 report-btn" @click="$emit('open-part-dialog')">
          <v-icon small>edit</v-icon>
        </v-btn>
      </div>
    </v-col>

    <PaidCalc
      class="mx-3"
      :hasInvoice="hasInvoice"
      :paidAmount="partPaidInvoice"
      :total="totalInvoice"
      :isdeposit="paidDeposit"
      :isCustomerPaymentPaid="isCustomerPaymentPaid"
    />
  </v-row>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";
import PaidCalc from "./_PaidCalc";
export default {
  props: {
    hasInvoice: Boolean,
    showHistory: Boolean,
    showInvoiceHistory: Boolean,
    sentDate: String,
    totalInvoice: Number,
    partPaidInvoice: Number,
  },
  components: {
    PaidCalc,
  },
  computed: {
    client_invoice() {
      // This should ALWAYS return an object
      return this.$store.getters["FinanceReportStore/client_invoice"];
    },
    client_invoice_total() {
      return this.$store.getters["FinanceReportStore/client_invoice_total"];
    },
    payment_history() {
      return this.$store.getters["FinanceReportStore/payment_history"];
    },
    bookingData() {
      return this.$store.getters["FinanceReportStore/bookingData"];
    },

    displayDate() {
      const v = this.sentDate;
      return v ? format.formatDate(v) : "";
    },
    displayClientTotal() {
      return _formatCost(this.client_invoice_total);
    },
    displayPartPaidTotal() {
      return _formatCost(this.partPaidInvoice);
    },

    paidDeposit() {
      if (!this.payment_history.length) return false;
      if (this.client_invoice.invoice_type === "DEPOSIT") return true;
      let v = JSON.parse(JSON.stringify(this.payment_history));
      let top = v.sort((a, b) => {
        if (a.paid_date < b.paid_date) return 1;
        if (a.paid_date > b.paid_date) return -1;
        return 0;
      });
      return top[0].isdeposit === 1;
    },
    isPaidColor() {
      if (this.partPaidInvoice > this.totalInvoice || this.paidDeposit) return "primary--text";
      if (this.partPaidInvoice === this.totalInvoice) return "green--text";
      if (0 < this.partPaidInvoice && this.partPaidInvoice < this.totalInvoice) return "warning--text";
      return "";
    },
    isCustomerPaymentPaid() {
      return this.bookingData?.["customer_payment_paid"] ?? false;
    },
  },
};

function _formatCost(v) {
  return v ? `$ ${money.format(v, { precision: 2 })}` : "$ --";
}
</script>
