<template>
  <a-card>
    <v-col>
      <v-row
        ><h3>Fixed Costs</h3>
        <v-btn
          small
          text
          color="primary"
          @click="openSupplierSettingDialog"
          :disabled="isSupplierSettingDialogDisabled()"
          >Supplier Setting</v-btn
        >
      </v-row>
      <table>
        <thead v-if="data.fixedCosts.length > 0">
          <tr>
            <th></th>
            <th>Supplier / City</th>
            <th>Product Name</th>
            <th>Product Type</th>
            <th>Product Cost</th>
            <th class="px-4">Unit</th>
            <th></th>
            <th class="px-2">Show in<br />the program</th>
            <th class="px-2">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(fixedCost, index) in data.fixedCosts" :key="index">
            <td>
              <f-text v-model="fixedCost.supplierCity" smlabel v-caps :disabled="isCityDisabled(index)" />
            </td>
            <td>
              <autocomplete-free-text
                v-model="fixedCost.supplier"
                :items="getSupplierItems(fixedCost.productType)"
                @input="onSupplierChanged(index, $event)"
              />
            </td>
            <td>
              <autocomplete-free-text
                v-model="fixedCost.product"
                :items="getProductItemsBySupplier(fixedCost.supplier)"
                @input="onProductChanged(data.period, index, $event)"
                :disabled="isProductNameDisabled(index)"
              />
            </td>
            <td>
              <f-dropdown
                v-model="fixedCost.productType"
                :items="getProductTypes"
                :disabled="isProductTypeDisabled(index)"
                @input="(v) => onProductTypeChanged(index, v)"
              />
            </td>
            <td><f-number v-model="fixedCost.productCost" :disabled="isCostDisabled(index)" /></td>
            <td align="center">{{ (fixedCost.days ?? []).length }}</td>
            <td>
              <v-btn
                icon
                small
                :disabled="isEditableCostDisabled(index)"
                @click="updateEditableCostStatus(data.period, index)"
              >
                <v-icon>{{ getEditableCostIconButton(index) }}</v-icon>
              </v-btn>
            </td>
            <td>
              <div class="d-flex justify-center"><v-switch :height="0" v-model="fixedCost.isShowInProgram" /></div>
            </td>
            <td align="center">
              <v-btn icon small @click="removeFixedCost(index)">
                <v-icon>clear</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <td colspan="5">
              <v-btn small text color="primary" @click="addFixedCost">+ Add new line</v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </v-col>
    <!-- Supplier Setting Dialog -->
    <SupplierSettingDialog v-model="isDialogShowed" :data="data" @update="(v) => updateData(v)" />
  </a-card>
</template>

<script>
import { ProductMenu } from "@/config/menus";
import quoteUtils from "@/modules/quotes/components/utils";
import SupplierSettingDialog from "./SupplierSettingDialog";
export default {
  data() {
    return {
      isDialogShowed: false,
    };
  },
  components: {
    SupplierSettingDialog,
  },
  props: {
    value: Object,
    supplierProductData: Object,
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    getProductTypes() {
      return ProductMenu.map((product) => ({ label: product.title, value: product.module }));
    },
  },
  methods: {
    updateData(data) {
      this.data = { ...data };
    },
    isSupplierSettingDialogDisabled() {
      const noVariableCosts = this.data.variableCosts.length === 0;
      const noRelevantFixedCosts =
        this.data.fixedCosts.filter((item) => item.productType === "TOUR" || item.productType === "TRANS").length === 0;
      return noVariableCosts || noRelevantFixedCosts;
    },
    openSupplierSettingDialog() {
      this.isDialogShowed = true;
    },
    addFixedCost() {
      this.data.fixedCosts.push({
        uuid: quoteUtils.generateUUID(),
        isShowInProgram: true,
        productCost: 0,
        isProductEditableCost: true,
      });
    },
    removeFixedCost(index) {
      this.data.fixedCosts.splice(index, 1);
    },
    isCityDisabled(index) {
      const supplier = this.data.fixedCosts[index]?.supplier;
      return !supplier || supplier.label == null || supplier.value != null;
    },
    isProductNameDisabled(index) {
      return this.data.fixedCosts[index]?.supplier == null;
    },
    isProductTypeDisabled(index) {
      return this.data.fixedCosts[index].product?.value != null;
    },
    isCostDisabled(index) {
      return !this.data.fixedCosts[index].isProductEditableCost;
    },
    isEditableCostDisabled(index) {
      return this.data.fixedCosts[index].product?.value == null;
    },
    getEditableCostIconButton(index) {
      return this.data.fixedCosts[index].isProductEditableCost ? "mdi-lock-outline" : "mdi-lock-off-outline";
    },
    getCurrentYear() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      return currentYear.toString();
    },
    updateEditableCostStatus(period, index) {
      const cost = this.data.fixedCosts[index];
      const newIsProductEditableCost = !cost.isProductEditableCost;
      cost.isProductEditableCost = newIsProductEditableCost;

      if (!newIsProductEditableCost && cost.product.value != null) {
        const product = this.supplierProductData[cost.supplier.value].products[cost.product.value];
        const rateYear = this.getCurrentYear();
        const totalCost = quoteUtils.getTotalCost(
          this.supplierProductData,
          product,
          rateYear,
          period.startMonth,
          period.startDay,
          period.endMonth,
          period.endDay
        );
        cost.productCost = product.product_type == "ROOM" ? totalCost / 2 : totalCost;
      }
    },
    getSupplierItems(productType) {
      const supplierType = quoteUtils.getSupplierTypeByProductType(productType);
      return Object.values(this.supplierProductData)
        .filter((item) => {
          const { supplier } = item;
          const { active, supplier_type, meta } = supplier;

          // Filter by selected supplier type and active status
          const isValidSupplierType = !supplierType || supplier_type === supplierType;
          const isActive = active === 1;

          // Check conditions for HOTEL/RESTAURANT
          const isValidAccommodation =
            supplier_type === "HOTEL" || supplier_type === "RESTAURANT"
              ? meta?.hotel_class == null || this.data.accommodations.includes(meta.hotel_class)
              : true;

          return isValidSupplierType && isActive && isValidAccommodation;
        })
        .map((item) => {
          const { id, meta, city, name } = item.supplier;
          const hotelClass = meta?.hotel_class ? `(${meta.hotel_class}) ${"*".repeat(meta.hotel_class)}` : null;
          const cityName = city ? `${city}` : null;
          const label = `${name}${hotelClass ? ` - ${hotelClass}` : ""}${cityName ? ` - ${cityName}` : ""}`;
          return { label: label, value: id, foc: meta.foc };
        });
    },
    getProductItemsBySupplier(supplier) {
      if (supplier == null) return [];
      if (supplier.value == null) return [];
      if (this.supplierProductData[supplier.value].products == null) return [];
      return Object.values(this.supplierProductData[supplier.value].products).map((item) => {
        const { id, name } = item;
        return { label: name, value: id };
      });
    },
    onSupplierChanged(index, v) {
      const fixedCost = this.data.fixedCosts[index];
      if (fixedCost.product != null) fixedCost.product = null;
      // fixedCost.productType = null;
      fixedCost.productCost = 0;
      fixedCost.isProductEditableCost = true;

      if (v.label === "" && v.value === null) {
        fixedCost.supplierCity = "";
        fixedCost.supplier = null;
      } else {
        fixedCost.supplier = v;
        if (v.value !== null) {
          fixedCost.supplierCity = (this.supplierProductData[v.value].supplier.city ?? "").toUpperCase();
          this.$emit("onSupplierChanged", v.value);
        }
      }
    },
    onProductChanged(period, index, v) {
      const variableCosts = this.data.variableCosts;
      const days = Array.from({ length: variableCosts.length }, (_, index) => index);
      const fixedCost = this.data.fixedCosts[index];
      fixedCost.product = v;

      if (v.value != null && fixedCost.supplier.value != null) {
        const product = this.supplierProductData[fixedCost.supplier.value].products[v.value];
        const rateYear = this.getCurrentYear();
        const totalCost = quoteUtils.getTotalCost(
          this.supplierProductData,
          product,
          rateYear,
          period.startMonth,
          period.startDay,
          period.endMonth,
          period.endDay
        );
        fixedCost.productType = product.product_type;
        fixedCost.days = fixedCost.productType === "TRANS" || fixedCost.productType === "TOUR" ? days : [0];
        fixedCost.productCost = fixedCost.productType == "ROOM" ? totalCost / 2 : totalCost;
        variableCosts.forEach((cost, i) => {
          const filteredItems = this.data.variableCosts[i].items.filter((item) =>
            ["ROOM", "MEAL"].includes(item.productType)
          );
          filteredItems.forEach((item) => {
            item.fixedCostUUIDs.push(fixedCost.uuid);
          });
        });
      }

      fixedCost.isProductEditableCost = v.value == null;
    },
    onProductTypeChanged(index, v) {
      const fixedCost = this.data.fixedCosts[index];
      fixedCost.product = null;
      fixedCost.productType = v;
      fixedCost.days = fixedCost.productType === "TRANS" || fixedCost.productType === "TOUR" ? [] : [0];
    },
  },
};
</script>
