<template>
  <v-form @submit.prevent="updateSearch(false)">
    <v-card-text>
      <v-row>
        <v-col class="pa-0">
          <a-select
            label="Group Name"
            :items="groupList"
            v-model="group_name"
            @input="(v) => $emit('set-supplierlist', { group_name: v })"
          />
        </v-col>
        <v-col class="pa-0">
          <a-select
            label="Supplier"
            :items="supplierListBlob"
            v-model="supplier_id"
            @change="(v) => (supplier_id = v)"
          />
        </v-col>

        <v-col cols="5" class="pa-0">
          <a-group icon="event">
            <a-date label="From" v-model="from" />
            <a-date label="To" v-model="to" />
          </a-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <a-select label="Supplier Type" widelabel :items="supplierTypes" v-model="supplier_type" />
        </v-col>
        <v-col class="pa-0">
          <a-select widelabel label="Client Invoice" :items="clientPaidOptions" v-model="clientPaid" />
        </v-col>

        <v-col class="pa-0">
          <a-select widelabel label="Reconciled" :items="chargeOptions" v-model="recondStatus" />
        </v-col>
      </v-row>

      <div class="d-flex justify-end">
        <!-- <span class="caption px-3">Reconciled:</span> -->
        <!-- <div v-for="status in statusOptions" :key="status.value" class="d-flex caption align-center"> -->
        <!--   <input type="checkbox" :value="status.value" v-model="selectedRec" /> -->
        <!--   <label class="px-2">{{ status.label }}</label> -->
        <!-- </div> -->

        <span class="caption px-3">Status:</span>
        <div class="d-flex caption align-center">
          <input type="checkbox" value="ACTIVE" v-model="archives" />
          <label class="px-2">Active</label>
          <input type="checkbox" value="CANCELLED" v-model="archives" />
          <label class="px-2">Cancelled</label>
          <input type="checkbox" value="ARCHIVED" v-model="archives" />
          <label class="px-2">Archived</label>
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <g-button label="Clear" @onclick="clearSearch" />
      <v-spacer />
      <div class="d-flex justify-end">
        <span class="caption px-3">Split Total by Currency</span>
        <div class="d-flex caption align-center">
          <input type="checkbox" v-model="splitTotal" @change="() => toggleSplit()" />
        </div>
      </div>
      <div class="d-flex justify-end px-3">
        <span class="caption px-3">Show Total</span>
        <div class="d-flex caption align-center">
          <input type="checkbox" v-model="showTotal" @change="(v) => toggleTotal(v)" />
        </div>
      </div>
      <search-button />
    </v-card-actions>
  </v-form>
</template>

<script>
import Vue from "vue";
import ENV from "@/config/ENV";
import PLACES from "@/config/fields/PLACES";
import DROP_OPTIONS from "./DROP_OPTIONS";
import { SuppliersMenu } from "@/config/menus";
import { DateHelper } from "@/modules/finances";

export default {
  props: {
    value: Object,
    groupList: Array,
    supplierListBlob: Array,
    showRecon: Array,
    showArchive: Array,
  },
  data() {
    return {
      clientPaidOptions: DROP_OPTIONS.CLIENT_PAID,
      chargeOptions: DROP_OPTIONS.CHARGE,
      statusOptions: DROP_OPTIONS.STATUS,
    };
  },

  computed: {
    group_name: {
      get() {
        return this.get("group_name") || "";
      },
      set(nuval) {
        this.set("group_name", nuval);
      },
    },
    supplier_id: {
      get() {
        return this.get("supplier_id") || null;
      },
      set(nuval) {
        this.set("supplier_id", nuval);
      },
    },
    from: {
      get() {
        return this.get("from") || "";
      },
      set(nuval) {
        this.set("from", nuval);
      },
    },
    to: {
      get() {
        return this.get("to") || "";
      },
      set(nuval) {
        this.set("to", nuval);
      },
    },
    clientPaid: {
      get() {
        return this.get("clientPaid") || null;
      },
      set(nuval) {
        this.set("clientPaid", nuval);
      },
    },
    recondStatus: {
      get() {
        return this.get("recondStatus") ?? null;
      },
      set(nuval) {
        this.set("recondStatus", nuval);
      },
    },

    showTotal: {
      get() {
        return this.get("showTotal") || true;
      },
      set(nuval) {
        this.set("showTotal", nuval);
      },
    },
    splitTotal: {
      get() {
        return this.get("splitTotal") || false;
      },
      set(nuval) {
        this.set("splitTotal", nuval);
      },
    },

    selectedRec: {
      get() {
        return this.showRecon;
      },
      set(v) {
        this.$emit("updateRecShow", v);
      },
    },
    archives: {
      get() {
        return this.showArchive;
      },
      set(v) {
        this.$emit("updateArchive", v);
      },
    },

    supplier_type: {
      get() {
        return this.get("supplier_type") || "ALL";
      },
      set(nuval) {
        this.set("supplier_type", nuval);
      },
    },
    supplierTypes() {
      return SuppliersMenu.FULL.map((item) => {
        return {
          label: item.title,
          value: item.module,
        };
      });
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["FinanceStore/supplierSearch"][val];
    },
    set(key, nuval) {
      let data = this.$store.getters["FinanceStore/supplierSearch"];
      Object.assign(data, { [key]: nuval });
      this.$store.dispatch("FinanceStore/updateSearch", { tab: "supplier", data });
    },

    clearSearch() {
      this.clientPaid = null;
      this.recondStatus = null;
      this.supplier_type = "ALL";

      this.$emit("input", {});
      this.$store.dispatch("FinanceStore/resetSearch");
    },
    updateSearch(showTotal) {
      const search = {
        ...(this.supplier_id && { supplier_id: this.supplier_id }),
        ...(this.from && { from: DateHelper.convertToYYYY_MM_DD(this.from) }),
        ...(this.to && { to: DateHelper.convertToYYYY_MM_DD(this.to) }),
        ...(this.supplier_type !== "ALL" && { supplier_type: this.supplier_type }),
        clientPaid: this.clientPaid,
        recondStatus: this.recondStatus,
      };
      this.$emit("input", search);
      // this.$store.dispatch("FinanceStore/applySearch", {
      //   tab: "supplier",
      //   data: search,
      // });
    },

    toggleTotal(v) {
      this.$emit("showTotal", this.showTotal);
    },
    toggleSplit() {
      this.$emit("splitTotal", this.splitTotal);
    },
  },
  mounted() {
    const search = {
      ...(this.supplier_id && { supplier_id: this.supplier_id }),
      ...(this.from && { from: DateHelper.convertToYYYY_MM_DD(this.from) }),
      ...(this.to && { to: DateHelper.convertToYYYY_MM_DD(this.to) }),
      ...(this.supplier_type !== "ALL" && { supplier_type: this.supplier_type }),
      clientPaid: this.clientPaid,
      recondStatus: this.recondStatus,
    };
    this.$emit("input", search);
  },
};
</script>
