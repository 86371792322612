import api from "./api";
import Vue from "vue";

const SET_ACCOUNT = "SET_ACCOUNT";
const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";

const ADD_LINKED_EMAIL = "ADD_LINKED_EMAIL";
const SET_LINKED_EMAIL = "SET_LINKED_EMAIL";
const UPDATE_LINKED_EMAIL = "UPDATE_LINKED_EMAIL";
const DELETE_LINKED_EMAIL = "DELETE_LINKED_EMAIL";

const namespaced = true;

var stored = JSON.parse(localStorage.getItem("account") || "{}");
var linkedEmailList = JSON.parse(localStorage.getItem("account_emails") || "[]");

const state = {
  id: stored.id,
  name: stored.name,
  email: stored.email,
  signature: stored.signature,
  env: stored.env,
  custom_access: stored.custom_access,
  linkedEmail: stored.linkedEmail,
  email_password: stored.email_password,

  linkedEmailList: linkedEmailList,
  hidden_reminders: stored.hidden_reminders,
};

const mutations = {
  SET_ACCOUNT(state, data) {
    // Should ONLY have name and email
    Object.assign(state, data || {});
    localStorage.setItem("account", JSON.stringify(state));
  },
  UPDATE_ACCOUNT(state, { key, val }) {
    state[key] = val;
  },

  SET_LINKED_EMAIL(state, data) {
    state.linkedEmailList = data;
    localStorage.setItem("account_emails", JSON.stringify(data));
  },
  ADD_LINKED_EMAIL(state, data) {
    state.linkedEmailList.push(data);
    localStorage.setItem("account_emails", JSON.stringify(state.linkedEmailList));
  },
  UPDATE_LINKED_EMAIL(state, data) {
    const source = state.linkedEmailList.find((item) => item.id === data.emailaccount_id);
    Object.assign(source, data.data);
    localStorage.setItem("account_emails", JSON.stringify(state.linkedEmailList));
  },
  DELETE_LINKED_EMAIL(state, source) {
    state.linkedEmailList = state.linkedEmailList.filter((item) => {
      return item.email_source !== source;
    });
    localStorage.setItem("account_emails", JSON.stringify(state.linkedEmailList));
  },
};

const actions = {
  saveLocalAccount({ commit }, data) {
    commit(SET_ACCOUNT, data);
  },
  updateAccount({ commit, state }, data) {
    // For NOT email and password
    return api
      .updateAccount(state.id, data)
      .then((res) => commit(SET_ACCOUNT, data))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  changeEmail({ commit }, creds) {
    return api
      .changeEmail(creds.new_email, creds.password)
      .then((res) => commit(SET_ACCOUNT, { email: creds.new_email }))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  changePassword({ commit }, creds) {
    return api.changePassword(creds.old_pasword, creds.new_password).catch((err) => {
      if (err) throw err.data;
    });
  },

  switchEnvironment({ commit, state }, mod) {
    return api
      .switchEnvironment(state.id, mod)
      .then((res) => commit(SET_ACCOUNT, { env: mod }))
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  /******
   * LINKED EMAILS
   * ****/
  getPersonalEmailList({ commit, state }) {
    return api
      .getPersonalEmailList(state.id)
      .then((data) => {
        commit(SET_LINKED_EMAIL, data.data);
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  addCustomEmail({ commit }, { account_id, source, data }) {
    return api
      .addCustomEmail(account_id, source, data)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  deleteCustomEmail({ commit }, customEmailId) {
    return api
      .deleteCustomEmail(customEmailId)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateCustomEmail({ commit }, { emailaccount_id, data }) {
    return api
      .updateCustomEmail(emailaccount_id, data)
      .then((v) => {
        commit(UPDATE_LINKED_EMAIL, { emailaccount_id, data });
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateEmailPassword({ commit, state }, { emailaccount_id, email_password }) {
    return api
      .updateEmailPassword(emailaccount_id, email_password)
      .then((v) => {
        commit(UPDATE_LINKED_EMAIL, {
          emailaccount_id,
          data: { email_password },
        });
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  /******
   * LINKED EMAILS -- SIGNATURE IMAGE
   * ****/
  addCustomSignatureImage({ commit }, data) {},
  deleteCustomSignatureImage({ commit }, image_id) {},
};

const getters = {
  id: (state) => state.id,
  name: (state) => state.name,
  email: (state) => state.email,
  signature: (state) => {
    const main = state.linkedEmailList.find((item) => item.ismain) || {};
    return main.signature;
  },
  environment: (state) => state.env,
  custom_access: (state) => state.custom_access || {},
  linkedEmail: (state) => state.linkedEmail,

  linkedEmailList: (state) => state.linkedEmailList || [],

  emailCreds: (state) => {
    const main = state.linkedEmailList.find((item) => item.ismain);
    if (!main) return null;
    return {
      email: main.email,
      source: main.email_source,
    };
  },
  hidden_reminders: (state) => JSON.parse(state.hidden_reminders) || [],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
