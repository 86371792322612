<template>
  <div>
    <v-snackbar dense top border="right" color="red" dark timeout="-1" v-model="hasOverdue">
      <span>OVERDUE: {{ overdueCount }} reminder{{ reminderCount === 1 ? "" : "s" }}.</span>
      <v-btn text small @click="markRemindersAsRead(true)"> Go to </v-btn>

      <template v-slot:action="{ attrs }">
        <v-btn text small icon v-bind="attrs" @click="hasOverdue = false">
          <v-icon small>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar dense top border="right" color="warning" dark v-model="hasAlert">
      <span>You have {{ reminderCount }} reminder{{ reminderCount === 1 ? "" : "s" }}.</span>
      <v-btn text small @click="markRemindersAsRead(true)"> Go to </v-btn>

      <template v-slot:action="{ attrs }">
        <v-btn text small icon v-bind="attrs" @click="hasAlert = false">
          <v-icon small>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
      reminderIdList: [],
      hasAlert: false,
      reminderCount: 0,

      hasOverdue: false,
      overdueCount: 0,
    };
  },
  methods: {
    getHiddenReminders() {
      return this.$store.getters["AccountStore/hidden_reminders"];
    },
    checkReminders() {
      const today = this.today;
      let savedReadReminders = localStorage.getItem("read_reminders") || null;
      if (savedReadReminders) {
        // Get today specifically
        savedReadReminders = JSON.parse(savedReadReminders)[today] || [];
        // Housekeeping: Clear older reminders not on this day
        localStorage.removeItem("read_reminders");
      } else {
        savedReadReminders = [];
      }

      // Get existing reminders
      this.$store
        .dispatch("ReminderStore/getMassReminders", {
          dateStart: today,
          dateEnd: today,
        })
        .then((mapping) => {
          if (!mapping) return;

          const reminders = mapping[today];
          if (!reminders || reminders.length === 0) return;

          const hiddenReminders = this.getHiddenReminders();
          this.reminderIdList = reminders
            .filter((reminder) => !hiddenReminders.includes(reminder.note))
            .map((item) => item.id);

          // Filter out any reminders that have been marked "read"
          this.reminderCount = this.reminderIdList.filter((value) => !savedReadReminders.includes(value)).length;

          // Show the rest
          if (this.reminderCount) {
            this.hasAlert = true;
          } else {
            this.markRemindersAsRead(false);
          }
        })
        .catch((err) => this.$root.$error(err));
    },
    markRemindersAsRead(shouldRedirect) {
      this.hasOverdue = false;
      this.hasAlert = false;
      localStorage.setItem(
        "read_reminders",
        JSON.stringify({
          [this.today]: this.reminderIdList,
        })
      );
      if (shouldRedirect) {
        // Navigate to reminder URL
        this.$router.push({ name: "reminders" });
      }
    },
    getOverdueReminders() {
      this.$store
        .dispatch("ReminderStore/getOverdueReminders", {
          isAdmin: this.$store.getters["isAdmin"],
          created_by: this.$store.getters["AccountStore/id"],
          beforeDate: moment(this.today).add(72, "hours").format("YYYY-MM-DD"),
        })
        .then((reminders) => {
          if (!reminders?.length) return;
          const hiddenReminders = this.getHiddenReminders();
          const filteredList = reminders?.filter((reminder) => !hiddenReminders.includes(reminder.note));
          if (!filteredList?.length) return;

          this.hasOverdue = true;
          this.overdueCount = filteredList.length;
        })
        .catch((err) => this.$root.$error(err));
    },
  },
  mounted() {
    this.checkReminders();
    this.getOverdueReminders();
  },
};
</script>

<style lang="scss"></style>
