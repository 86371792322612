import { BookingDayService } from "@/modules/bookings.days";
import { ProductService } from "@/modules/products";
import SUPMENU from "@/config/menus/suppliersMenu";
import format from "@/services/format";
import money from "@/services/money";

export default {
  // Compiling Blobs
  compileSupplierBreakdown,
  compileProductPrices,
};

/****************
 * COMPILING BLOBS
 * ************/
function compileSupplierBreakdown(booking_suppliers, compiledProductsRef) {
  /***
   * Calculates total of commisions
   * PARAMS
   *  booking_suppliers       @{Object} bsid => booking_suppliers
   *  compiledProductsRef     @{Object}
   * RETURN
   *  @Object => Grouped supplier (by type) with compiled products
   * ********/

  let blob = {};
  let supid;

  SUPMENU.FULL.forEach((item) => {
    if (item.module === "ALL") return;
    blob[item.module] = {};
  });

  // Extract supplier info (merge seperate booking suppleirs into one)
  // Need locked prices only
  // If no locked prices, not confirmed
  Object.values(booking_suppliers).forEach((supplier) => {
    if (supplier.nest_type === "ALT") return;
    // bs meta stuff, for confirmed room total and product count
    let typeAs = supplier.type_as;

    supid = supplier.supplier_id;
    if (!blob[typeAs][supid]) {
      blob[typeAs][supid] = {
        id: supplier.id,
        supplier_id: supid,
        name: supplier.name,
        supplier_env: supplier.supplier_env,
        type_as: supplier.type_as,
        meta: supplier.meta,
        supplier_meta: supplier.supplier_meta,
        products: [],
      };
    }
    blob[typeAs][supid].products = [
      ...blob[typeAs][supid].products,
      ...Object.values(supplier.products || {}).map((product) => {
        return compiledProductsRef[`${supplier.id}_${product.id}`];
      }),
      ...[
        compiledProductsRef[`${supplier.id}_PORTERAGE`],
        compiledProductsRef[`${supplier.id}_PARKING_VEHICLE`],
        compiledProductsRef[`${supplier.id}_PARKING_BUS`],
      ].filter((i) => i && i.compiledTotal),
    ].filter((i) => i); // Strip nulls
  });

  // Shift Hotel Meals to be listed + grouped with Hotel products
  // Do not display under restaurant
  if (blob.RESTAURANT && blob.HOTEL) {
    Object.values(blob.RESTAURANT).forEach((supplier) => {
      supid = supplier.supplier_id;
      if (supid in blob.HOTEL) {
        blob.HOTEL[supid].products = [...blob.HOTEL[supid].products, ...supplier.products];
        delete blob.RESTAURANT[supid];
      }
    });
  }

  return blob;
}

function compileProductPrices({ pax, booking_suppliers, booking_days, roomBreakdown, storeGetter, errorPopup }) {
  /***
   * Calculates total of commisions
   * PARAMS
   *  pax                 @{Number} The default PAX of main booking
   *  booking_suppliers   @{Object} bsid => booking_suppliers
   *  booking_days        @{Array} list of booking days
   *  roomBreakdown       @{Object} Compiled/Breakdown of rooming list
   *  storeGetter         @{Store} Vue store
   *  errorPopup          @{Function} Callback function to assist with error catching
   * RETURN
   *  @Object => Grouped supplier (by type) with compiled products
   * ********/
  let productRef = {};
  let cusname, count, roomCountByDate;

  // TODO: Temporarily remove the duplicated supplier removal code. wll delete the code below if everything works fine.
  // // remove duplicate supplier, remap booking_days
  // const removedDuplicateBookingSuppliers = {};
  // const remapedId = {};
  // Object.values(booking_suppliers).forEach((supplier) =>
  //   supplier.supplier_id in removedDuplicateBookingSuppliers
  //     ? (remapedId[supplier.id] = supplier.supplier_id)
  //     : (removedDuplicateBookingSuppliers[supplier.supplier_id] = supplier)
  // );
  // Object.entries(remapedId).forEach(([key, value]) => (remapedId[key] = removedDuplicateBookingSuppliers[value].id));

  // function remapBookingId(day, key) {
  //   return day[key].map((id) => (id in remapedId ? remapedId[id] : id));
  // }
  // const remapedBookingDays = booking_days.map((day) => {
  //   const excursions = remapBookingId(day, "excursions");
  //   const hotels = remapBookingId(day, "hotels");
  //   const parks = remapBookingId(day, "parks");
  //   const restaurants = remapBookingId(day, "restaurants");
  //   const supplier_rooms = remapBookingId(day, "supplier_rooms");
  //   const tour_guides = remapBookingId(day, "tour_guides");
  //   const transport = remapBookingId(day, "transport");
  //   return { ...day, excursions, hotels, parks, restaurants, supplier_rooms, tour_guides, transport };
  // });
  // const remapedBookingSuppliers = {};
  // Object.entries(removedDuplicateBookingSuppliers).forEach(
  //   ([key, value]) => (remapedBookingSuppliers[value.id] = removedDuplicateBookingSuppliers[key])
  // );

  const remapedBookingSuppliers = booking_suppliers;
  const remapedBookingDays = booking_days;

  Object.values(remapedBookingSuppliers).forEach((bs) => {
    const bsmeta = bs.meta;
    const lockedPrices = bs.locked_prices || {};

    const whatDays = BookingDayService.whatDays(bs.type_as, bs.id, remapedBookingDays);

    // BUG WORKAROUND -- undeleted booking suppliers?
    if (!whatDays.length) return;

    _transferUnconfirmedPricesToLocked(bs, bs.products || {}, lockedPrices, storeGetter, whatDays[0], errorPopup);

    let metaMod = {
      ...JSON.parse(JSON.stringify(bsmeta)), // Copy to avoid modification
      ...BookingDayService.setConfirmedRoomData({
        item: bs,
        status: 10,
        existingMeta: bsmeta,
        roomBreakdownData: roomBreakdown,
        dayinfo: remapedBookingDays,
      }),
      ...BookingDayService.setConfirmedCount({
        env: storeGetter["AccountStore/environment"],
        item: bs,
        existingMeta: bsmeta,
        bookingSuppliers: remapedBookingSuppliers,
        days: remapedBookingDays,
        // dayinfo,
        overwrite: false,
      }),
    };

    Object.values(bs.products || {}).forEach((product) => {
      cusname = metaMod.custom_names && metaMod.custom_names[product.id];
      count = (metaMod.confirmed_count_total && metaMod.confirmed_count_total[product.id]) || {};
      roomCountByDate = metaMod.confirmed_roomcount;

      // HACK / BUGN WORK AROUND
      if (roomCountByDate && roomCountByDate.using) {
        let fixed = {};
        whatDays.forEach((date) => {
          fixed[date] = roomCountByDate;
        });
        roomCountByDate = fixed;
      }
      productRef[`${bs.id}_${product.id}`] = {
        id: product.id,
        name: cusname || product.name,
        supplier_id: bs.supplier_id,
        supplier_env: bs.supplier_env,
        type_as: bs.type_as,
        product_type: product.product_type,
        tax_inclusive: product.tax_inclusive,
        product_meta: product.meta,
        ..._extractProductCompiledTotal({
          dateList: whatDays,
          productId: product.id,
          lockedPrices: lockedPrices,
          typeAs: bs.type_as,
          foc: _getFOC(bs),
          pax,
          count,
          roomCountByDate,
          product,
          supplier: bs,
          storeGetter,
        }),
      };

      // Fill specialized products
      if (bs.type_as === "HOTEL") {
        productRef[`${bs.id}_PORTERAGE`] = {
          id: "PORTERAGE",
          name: "Porterage",
          supplier_id: bs.supplier_id,
          type_as: bs.type_as,
          product_type: "HARDCODE",
          tax_inclusive: product.tax_inclusive, // Via assuming same as hotel room
          product_meta: product.meta,
          // Assuming pax + max supplier room for hotel is roomcount
          ..._extractPorterage(metaMod, bs.supplier_meta, roomCountByDate, pax),
        };

        productRef[`${bs.id}_PARKING_VEHICLE`] = {
          id: "PARKING_VEHICLE",
          name: "Parking (Vehicles)",
          supplier_id: bs.supplier_id,
          type_as: bs.type_as,
          product_type: "HARDCODE",
          tax_inclusive: product.tax_inclusive, // Via assuming same as hotel room
          product_meta: product.meta,
          ..._extractVehicleParking(metaMod, bs.supplier_meta, whatDays),
        };

        productRef[`${bs.id}_PARKING_BUS`] = {
          id: "PARKING_BUS",
          name: "Parking (Buses)",
          supplier_id: bs.supplier_id,
          type_as: bs.type_as,
          product_type: "HARDCODE",
          tax_inclusive: product.tax_inclusive, // Via assuming same as hotel room
          product_meta: product.meta,
          ..._extractBusParking(metaMod, bs.supplier_meta, whatDays),
        };
      }
    });
  });

  return productRef;
}

function _transferUnconfirmedPricesToLocked(supplier, products, output, storeGetter, useDate, errorPopup) {
  // Modifier function
  const compile = ProductService.lockPrices(products, supplier, storeGetter, useDate, errorPopup);

  Object.values(products).forEach((product) => {
    let use = __extractNestedFilter(compile[product.id]);

    if (output[product.id]) {
      if (!output[product.id].totalPrice) output[product.id].totalPrice = use.totalPrice || {};
      if (!output[product.id].originalPrice) output[product.id].originalPrice = use.originalPrice || {};
      return;
    }
    output[product.id] = use;
    output[product.id].unconfirmed = true;
  });
}

function __extractNestedFilter(blob) {
  if (!blob) return {};
  if (blob[0]) return blob[0];
  return blob;
}

function _getFOC(supplier) {
  if (!supplier.supplier_meta || !supplier.supplier_meta.foc) return null;
  if (supplier.supplier_meta.foc.type !== "RATE") return null;

  return {
    value: money._num(supplier.supplier_meta.foc.value),
    per: money._num(supplier.supplier_meta.foc.per),
  };
}

function _extractProductCompiledTotal({
  productId,
  lockedPrices,
  typeAs,
  pax,
  count,
  foc,
  dateList,
  roomCountByDate,
  product,
  supplier,
  storeGetter,
}) {
  const dayCount = dateList.length;
  if (!lockedPrices[productId]) return { dayCount };

  let productPrice = lockedPrices[productId];
  let productRate = productPrice.totalPrice || (productPrice[0] || {}).totalPrice;
  let originalRate = productPrice.originalPrice || (productPrice[0] || {}).originalPrice;

  if (!productRate || !originalRate) return { dayCount };

  let temp = {},
    originalTemp = {};
  if (typeAs === "HOTEL") {
    // Hotels need to account for room
    temp = _getRoomTotal(productRate, roomCountByDate, pax, foc, product, supplier, dateList, storeGetter);
    originalTemp = _getRoomTotal(originalRate, roomCountByDate, pax, foc, product, supplier, dateList, storeGetter);
  } else {
    temp = _getGeneralSupplierTotal(productRate, pax, count, dateList, foc);
    originalTemp = _getGeneralSupplierTotal(originalRate, pax, count, dateList, foc);
  }

  return {
    ...temp,
    original: originalTemp,
    dayCount,
  };
}

function _getRoomTotal(productRate, roomCountByDate, pax, foc, product, supplier, dateList, storeGetter) {
  // PARAMS
  //	productPrice	@{Object}	Product's rate information
  //	roomCount		@{Object}	The date => booking room breakdown (including supplier rooms)
  //  pax             @{Number}   Total PAX
  //	foc 			@{Object}	FOC
  // RETURN @{Number} 	Total number of rooms

  const rateType = productRate.type;
  const value = money._num(productRate.raw_value || money.stripFormatting(productRate.value));

  let breakCount = 0,
    perTotal;

  let totalCountOfItems = 0;
  let subpricing = {},
    displayFront = "",
    displayNames = "";
  Object.values(roomCountByDate).forEach((roomCount) => {
    if (rateType === "PROOM" || !rateType) {
      // Price per room, so get total rooms
      breakCount = roomCount.room_total || 0;
      perTotal = `${breakCount} ${breakCount === 1 ? "Room" : "Rooms"}`;
      // console.log(`PROOM: ${perTotal}`)
    } else if (rateType === "PP") {
      // Price per persion, get total count
      breakCount = roomCount.pax_total || pax || 0;
      perTotal = `${breakCount} ${breakCount === 1 ? "Person" : "People"}`;
    } else if (rateType === "PG") {
      breakCount = 1;
      perTotal = `${breakCount} ${breakCount === 1 ? "Group" : "Groups"}`;
    } else {
      // No rate type, assume total rooms
      breakCount = roomCount.room_total || roomCount.pax_total || pax || 0;
      perTotal = `${breakCount} Each`;
    }

    totalCountOfItems += breakCount;

    // Add Suppliers to Count
    // Suppliers are 1 room = 1 person, so same regardless of rate type
    // Count refers to number of nights a specific supplier is
    roomCount.extraFilter.forEach((item) => {
      if (subpricing[item.id]) {
        subpricing[item.id].count += 1;
        return;
      }

      if (rateType == "PG") {
        return;
      }

      displayFront = item.type_as === "TOUR_GUIDE" ? "Tour Guide" : "Bus Driver";
      displayNames = [item.first_name, item.last_name].join(" ");
      subpricing[item.id] = {
        type_as: item.type_as,
        displayName: `${displayFront} ${displayNames ? "(" + displayNames + ")" : ""}`,
        count: 1,
        perTotal: `1 ${rateType === "PROOM" ? "Room" : "Person"}`,
        compiledTotal: money._num(value) * 1,
        productRate: ProductService.formatPrice({
          ...productRate,
          ...{ value },
        }),
      };
    });
  });

  // FOC Adjustment
  let howManyFOC = 0;
  let subPriceTotal = Object.values(subpricing).reduce((total, s) => total + s.count, 0);

  if (foc && Number(foc.per) > 0) {
    let focTop = foc.value;
    let focBase = foc.per;
    let totalWithSuppliers = totalCountOfItems + subPriceTotal;

    howManyFOC = Math.floor((totalWithSuppliers - Math.floor(totalWithSuppliers / focBase)) / focBase) * focTop;
    totalCountOfItems -= howManyFOC;

    perTotal += ` (${focTop}/${focBase} FOC)`;
    // console.log(`focTop: ${focTop}, focBase: ${focBase}, totalWithSuppliers: ${totalWithSuppliers}`)
    // console.log(`((${totalWithSuppliers} - ${focTop}) / ${focBase}) * ${focTop}`)
    // console.log(`howManyFOC: ${howManyFOC}`)
  }

  let perRoomPrice = 0,
    perRoom = [];

  // Calculate per room type price when the product has per room data
  if (rateType === "PROOM") {
    // Track whether the original room types 'SINGLE_DK' or 'DOUBLE_QK' exist before merging them into 'SINGLE_DOUBLE'.
    let isSingleRoom = false;
    let isDoubleRoom = false;

    // Extract room from roomCountByDate.room_breakdown object
    const roomByDates = Object.values(roomCountByDate);
    const dayCount = roomByDates.length;
    const rooms = roomByDates.reduce((previous, current) => {
      Object.entries(current.room_breakdown).forEach(([type, total]) => {
        const newRoomType = type === "SINGLE_DK" || type === "DOUBLE_QK" ? "SINGLE_DOUBLE" : type;
        return newRoomType in previous
          ? (previous[newRoomType] += parseInt(total, 10))
          : (previous[newRoomType] = parseInt(total, 10));
      });
      return previous;
    }, {});

    // Above is roomByDates.length * roomTotal
    // so it has to divided
    const roomEachDay = Object.fromEntries(Object.entries(rooms).map(([type, total]) => [type, total / dayCount]));

    Object.entries(roomEachDay).forEach(([type, total]) => {
      const calculatedPerRoom = _calculatePerRoom({
        defaultPrice: money._num(value),
        product,
        supplier,
        roomType: type,
        roomTotal: total,
        productRate,
        howManyFOC,
        foc,
        // subpricing,
        dayCount,
        dateList,
        storeGetter,
      });
      perRoomPrice += calculatedPerRoom.compiledTotal;
      perRoom.push(calculatedPerRoom);

      // change subroom price
      if (type === "SINGLE_DOUBLE") {
        Object.entries(subpricing).forEach(([key, value]) => {
          const compiledTotal = value.count * money.stripFormatting(calculatedPerRoom.productRate);
          perRoomPrice += compiledTotal;
          return (subpricing[key] = {
            ...value,
            compiledTotal,
            productRate: calculatedPerRoom.productRate,
            original: calculatedPerRoom.original
              ? {
                  productRate: calculatedPerRoom.original.productRate,
                }
              : null,
          });
        });
      }
    });
  }

  let compiledTotal;
  switch (rateType) {
    case "PROOM":
      compiledTotal = perRoomPrice;
      break;
    case "PG":
      compiledTotal = money._num(value) * totalCountOfItems;
      break;
    default:
      compiledTotal = money._num(value) * (totalCountOfItems + subPriceTotal);
      break;
  }

  return {
    productRate: ProductService.formatPrice({
      ...productRate,
      ...{ value },
    }),
    perTotal,
    hasfoc: !!foc,
    howManyFOC,
    totalCountOfItems: totalCountOfItems + subPriceTotal + howManyFOC,
    compiledTotal,
    subpricing: Object.values(subpricing).length ? Object.values(subpricing) : null,
    perRoom,
  };
}

function _calculatePerRoom({
  defaultPrice,
  product,
  supplier,
  roomType,
  roomTotal,
  productRate,
  howManyFOC,
  foc,
  // subpricing,
  dayCount,
  dateList,
  storeGetter,
}) {
  // Initialize variables for room price and the final calculated total
  let roomPrice;
  let roomPriceWithModifier;
  let compiledTotal = 0;
  let isDefaultPrice;

  // Metadata for product and supplier
  const productMeta = product.meta;
  const supplierMeta = supplier.supplier_meta;

  // Get custom prices defined for the supplier (if any)
  const customPrices = supplier.meta?.custom_prices || {};
  const customPrice = customPrices ? customPrices[product.id] : null;
  const hasCustomPrices = customPrice != null;
  const isSeasonal = product.seasonal;

  if (isSeasonal) {
    // Seasonal Price
    // Get seasonal price ranges based on the first date in the date list
    const seasonalPrices = ProductService.getSeasonalRange(product.rate, {
      from_month: format.dateToObj(dateList[0]).month,
      from_day: format.dateToObj(dateList[0]).day,
    });

    if (hasCustomPrices) {
      // Replace rate with custom price
      seasonalPrices.forEach((price, i) => {
        if (customPrice && customPrice[i]) {
          price.rate = customPrice[i];
        }
      });
    }

    // Find the default, single/double, and null seasonal prices
    const defaultSeasonalPrice = seasonalPrices.find((price) => price.roomType === "DEFAULT");
    const singleDoublelSeasonalPrice = seasonalPrices.find((price) => price.roomType === "SINGLE_DOUBLE");
    const nullSeasonalPrice = seasonalPrices.find((price) => price.roomType === null || price.roomType === undefined);

    // Determine the default price to use
    let fallbackPrice = defaultSeasonalPrice || singleDoublelSeasonalPrice || nullSeasonalPrice;
    let matchedlPrice;

    // If the room type is one of the specified, select the corresponding seasonal price
    if (["SINGLE_DOUBLE", "DOUBLE_DOUBLE", "TRIPLE", "QUAD"].includes(roomType)) {
      matchedlPrice = seasonalPrices.find((price) => price.roomType == roomType);
    }

    // If no price was found for the roomType, fallback to the default or null price
    let defaultPrice = matchedlPrice;
    if (!defaultPrice) {
      defaultPrice = fallbackPrice;
    }

    // Calculate additional costs and apply extras
    const additionalCosts = matchedlPrice ? 0 : _calculateAdditionalPrice(storeGetter, roomType);
    const priceWithExtras = _calculatePriceWithExtras(
      defaultPrice.rate,
      product,
      supplier.meta,
      supplier.supplier_meta
    );
    const totalPrice = additionalCosts + priceWithExtras;
    const taxRate = _getTax(product, supplier.supplier_meta);

    roomPrice = defaultPrice?.rate?.value != null ? money.stripFormatting(defaultPrice.rate.value) : 0;
    roomPriceWithModifier = totalPrice + totalPrice * (taxRate / 100);

    isDefaultPrice = matchedlPrice == null && roomType !== "SINGLE_DOUBLE";
  } else {
    // Fiet Price
    let roomRate;
    let matchedRate;

    const perRoomRates = productMeta.per_room;
    const hasSpecificRoomRate = perRoomRates && perRoomRates.length > 0;

    if (hasSpecificRoomRate) {
      const normalizedRoomType = roomType == "SINGLE_DOUBLE" ? "SINGLE_DK" : roomType;
      matchedRate = perRoomRates.find((rate) => rate.type.includes(normalizedRoomType));
      roomRate = matchedRate || product.rate;
    } else {
      roomRate = product.rate;
    }

    if (hasCustomPrices) roomRate = customPrice;

    // Calculate room price
    const additionalCosts = matchedRate ? 0 : _calculateAdditionalPrice(storeGetter, roomType);
    const priceWithExtras = _calculatePriceWithExtras(roomRate, product, supplier.meta, supplier.supplier_meta);
    const totalPrice = additionalCosts + priceWithExtras;
    const taxRate = _getTax(product, supplier.supplier_meta);

    roomPrice = roomRate?.value != null ? money.stripFormatting(roomRate.value) : 0;
    roomPriceWithModifier = totalPrice + totalPrice * (taxRate / 100);

    isDefaultPrice = matchedRate == null && roomType !== "SINGLE_DOUBLE";
  }

  // Calculate total number of rooms based on room count and day count
  const roomCount = roomTotal * dayCount;

  // Calculate total price based on room type and number of FOC (free-of-charge) rooms
  let perTotal = `${roomTotal} ${roomTotal === 1 ? "Room" : "Rooms"}`;
  if (roomType === "SINGLE_DOUBLE" && foc) {
    compiledTotal = roomPriceWithModifier * (roomCount - howManyFOC);
  } else {
    compiledTotal = roomPriceWithModifier * roomCount;
  }

  // Determine the display name for the room type
  let roomName;
  switch (roomType) {
    case "SINGLE_DOUBLE":
      roomName = "Single/Double";
      break;
    case "DOUBLE_DOUBLE":
      roomName = "Twin";
      break;
    case "TRIPLE":
      roomName = "Triple";
      break;
    case "QUAD":
      roomName = "Quad";
      break;
  }

  // Return the final calculated data
  return {
    name: roomName,
    type: roomType,
    totalCountOfItems: roomCount,
    perTotal,
    hasfoc: !!foc,
    howManyFOC,
    productRate: ProductService.formatPrice({ ...productRate, value: roomPriceWithModifier }),
    compiledTotal,
    // subpricing: Object.values(subpricing),
    original:
      roomPrice === defaultPrice
        ? null
        : {
            productRate: ProductService.formatPrice({ ...productRate, value: roomPrice }),
          },
    isDefaultPrice: isDefaultPrice,
  };
}

function _getGeneralSupplierTotal(productRate, pax, countBreakdown, dayList, foc) {
  const rateType = productRate.type;
  const value = money.stripFormatting(productRate.raw_value || productRate.value);
  let breakCount, perTotal;

  // FOC Adjustment
  let totalCountOfItems = 0;
  let howManyFOC = 0;

  if (rateType === "PP") {
    // PER PERSON
    breakCount = countBreakdown.count || pax;
    perTotal = `${breakCount} ${breakCount === 1 ? "Person" : "People"}`;
  } else if (rateType === "PG" || rateType === "PD") {
    // PER GROUP
    breakCount = countBreakdown.count || 1;
    if (breakCount > 1) perTotal = breakCount;
  } else {
    breakCount = countBreakdown.count || 1;
    perTotal = `${breakCount}`;
  }

  totalCountOfItems = breakCount * dayList.length;

  // Add Suppliers to Count
  // Suppliers can be tour guide or bus driver
  // Only need count total if
  let tourGuideMapping = {},
    transportMapping = {},
    tgnum,
    transnum;
  dayList.forEach((date) => {
    if (countBreakdown.tour_guides && countBreakdown.tour_guides[date]) {
      tgnum = countBreakdown.tour_guides[date];
      if (!tourGuideMapping[tgnum]) tourGuideMapping[tgnum] = [];
      tourGuideMapping[tgnum].push(date);
    }
    if (countBreakdown.transport && countBreakdown.transport[date]) {
      transnum = countBreakdown.transport[date];
      if (!transportMapping[transnum]) transportMapping[transnum] = [];
      transportMapping[transnum].push(date);
    }
  });

  let subPriceTotal = 0,
    subcount;
  let subpricing = [];
  Object.keys(tourGuideMapping).forEach((c) => {
    subcount = tourGuideMapping[c].length;
    subPriceTotal += c * subcount;
    subpricing.push({
      type_as: "MEAL", // For now...
      displayName: "Tour Guide",
      count: subcount,
      perTotal: rateType === "PP" ? `${c} ${Number(c) === 1 ? "Person" : "People"}` : c,
      compiledTotal: money._num(value) * subcount * c,
    });
  });
  Object.keys(transportMapping).forEach((c) => {
    subcount = transportMapping[c].length;
    subPriceTotal += c * subcount;
    subpricing.push({
      type_as: "MEAL", // For now...
      displayName: "Bus Driver",
      count: subcount,
      perTotal: rateType === "PP" ? `${c} ${Number(c) === 1 ? "Person" : "People"}` : c,
      compiledTotal: money._num(value) * subcount * c,
    });
  });

  // Free of charge adjustment
  if (foc) {
    let focTop = foc.value;
    let focBase = foc.per || 1;
    let totalWithSuppliers = totalCountOfItems + subPriceTotal;

    howManyFOC = Math.floor((totalWithSuppliers - Math.floor(totalWithSuppliers / focBase)) / focBase) * focTop;
    totalCountOfItems -= totalCountOfItems >= howManyFOC ? howManyFOC : 0;

    perTotal += ` (${focTop}/${focBase} FOC)`;
    // console.log(`charge adj : ${perTotal}`)
  }

  return {
    productRate: ProductService.formatPrice(productRate),
    perTotal,
    hasfoc: !!foc,
    howManyFOC,
    totalCountOfItems: totalCountOfItems + subPriceTotal + howManyFOC,
    compiledTotal: money._num(value) * (totalCountOfItems + subPriceTotal),
    subpricing,
  };
}

function _extractPorterage(bsMeta, supplierMeta, roomCountByDate, pax) {
  if (!supplierMeta || !supplierMeta.porterage) return null;
  if (supplierMeta.porterage.type === "TEXT") return null;
  if (supplierMeta.porterage.text === "optional" && !bsMeta.porterage) return null;

  const stripped = money.stripFormatting(supplierMeta.porterage.value);
  let countTotal = pax;
  if (!pax) {
    const roomCount = roomCountByDate.using ? roomCountByDate : Object.values(roomCountByDate)[0];
    countTotal = roomCount.pax_total + roomCount.extraTotal;
  }

  return {
    totalCountOfItems: countTotal,
    rate: supplierMeta.porterage.value,
    compiledTotal: money._num(stripped) * countTotal,
  };
}

function _extractVehicleParking(bsMeta, supplierMeta, whatDays) {
  if (!supplierMeta || !supplierMeta.parking || !supplierMeta.parking.per_vehicle) return null;
  if (!bsMeta || !bsMeta.parking || !bsMeta.parking.parking_vehicle_count) return null;

  const stripped = money.stripFormatting(supplierMeta.parking.per_vehicle.value);
  const dayCount = whatDays.length;

  return {
    totalCountOfItems: bsMeta.parking.parking_vehicle_count,
    rate: supplierMeta.parking.per_vehicle.value,
    dayCount,
    compiledTotal: money._num(stripped) * dayCount * money._num(bsMeta.parking.parking_vehicle_count),
  };
}

function _extractBusParking(bsMeta, supplierMeta, whatDays) {
  if (!supplierMeta || !supplierMeta.parking || !supplierMeta.parking.per_bus) return null;
  if (!bsMeta || !bsMeta.parking || !bsMeta.parking.parking_bus_count) return null;

  const stripped = money.stripFormatting(supplierMeta.parking.per_bus.value);
  const dayCount = whatDays.length;

  return {
    totalCountOfItems: bsMeta.parking.parking_bus_count,
    rate: supplierMeta.parking.per_bus.value,
    dayCount,
    compiledTotal: money._num(stripped) * dayCount * money._num(bsMeta.parking.parking_bus_count),
  };
}

function _getTax(product, supplierMeta) {
  // Tax
  const customTax = product.meta?.custom_tax || 0;
  const tax = customTax || supplierMeta?.tax || 0;
  return tax;
}

function _calculatePriceWithExtras(rate, product, bookingSupplierMeta, supplierMeta) {
  if (rate == null) return 0;

  // Extra list
  const extraList = ProductService.getExtraList(product);

  // Occupancy rate for rooms
  const occupancy =
    product.product_type === "ROOM"
      ? (product.meta && product.meta.custom_occupancy) || supplierMeta.occupancy_rate
      : 0;

  // Resort fee handling
  const cusresort =
    bookingSupplierMeta && bookingSupplierMeta.has_custom_resortfee && bookingSupplierMeta.custom_resortfee_value.value
      ? bookingSupplierMeta.custom_resortfee_value
      : null;
  const resortFee = product.product_type === "ROOM" ? cusresort || supplierMeta.resort_fee : 0;

  return ProductService.calcTotal({
    rateInfo: rate,
    tax: _getTax(product, supplierMeta),
    taxInclusive: 1,
    extraList: extraList,
    occupancy: occupancy,
    resort_fee: resortFee,
  });
}

function _calculateAdditionalPrice(storeGetter, roomType) {
  const environment = storeGetter["AccountStore/environment"];
  const priceModifiers = {
    USA: {
      TRIPLE: 20,
      DOUBLE_DOUBLE: 20,
      QUAD: 40,
    },
    CDA: {
      TRIPLE: 20,
      QUAD: 40,
    },
  };
  return priceModifiers[environment]?.[roomType] || 0;
}
