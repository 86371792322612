const short_uuid = require("short-uuid");
const translator = short_uuid();

import ExcelJS from "exceljs";
import { ExportService } from "@/modules/exports";
import ProductService from "@/modules/products/service";
import MONTHS from "@/config/MONTHS";

// Generate uuid of variable cost or fixed cost
function generateUUID() {
  return translator.generate();
}

function getTotalCostByPax(variableCosts, fixedCosts, peopleRanges) {
  const totalCostByPax = [];
  peopleRanges.forEach((peopleRange, index) => {
    totalCostByPax.push(getTotalCostByCustomPax(variableCosts, fixedCosts, peopleRange.min));
  });
  return totalCostByPax;
}

function getTotalCostByCustomPax(variableCosts, fixedCosts, pax) {
  const numberOfClients = Number(pax);
  if (numberOfClients == 0) return 0;

  let totalVariableCosts = 0;
  let totalFixedCosts = 0;

  // Calculate total variable costs
  variableCosts.forEach((variableCost) => {
    variableCost.items.forEach((item) => {
      let numberOfSuppliers = (item.fixedCostUUIDs || []).length;
      if (item.productType === "ROOM") {
        numberOfSuppliers *= 2;
      }
      const totalNumber = numberOfClients + numberOfSuppliers;
      let focBase = item.supplier?.foc?.per ?? 1; // 20
      let focTop = item.supplier?.foc?.value ?? 0; // 1
      if (focTop === "no") focTop = 0;

      let howManyFOC = 0;

      if (item.productType === "ROOM") {
        // We apply a specific rule for hotels to handle the price "per Pax" (in the quote) vs price "Per room" (in the product page)

        focBase *= 2;
        howManyFOC = Math.floor((totalNumber - Math.floor(totalNumber / focBase)) / focBase) * focTop;
        howManyFOC *= 2;
      } else {
        howManyFOC = Math.floor((totalNumber - Math.floor(totalNumber / focBase)) / focBase) * focTop;
      }

      const total = item.productCost * (totalNumber - howManyFOC);
      totalVariableCosts += total;
    });
  });

  // Calculate total fixed costs
  fixedCosts.forEach((fixedCost) => {
    const numberOfDays = (fixedCost.days || []).length;
    const total = fixedCost.productCost * numberOfDays;
    totalFixedCosts += total;
  });

  // Calculate total cost per pax
  return (totalVariableCosts + totalFixedCosts) / numberOfClients;
}

// Get Total Cost of product based on `_TotalShow.vue` file
function getTotalCost(suppliers, product, rateYear, startMonth, startDay, endMonth, endDay) {
  const rateHistory = product.rate_history.find((history) => history.rate_year === rateYear);
  if (!rateHistory || rateHistory.rate === null) return 0;

  let rateInfo;
  if (rateHistory.seasonal === 1) {
    if (!startMonth && !startDay && !endMonth && !endDay) return 0;
    const seasonalRate = rateHistory.rate.find(
      (rate) =>
        _isInDateRange(rate, startMonth, startDay, endMonth, endDay) && (!rate.roomType || rate.roomType === "DEFAULT")
    );

    if (!seasonalRate) return 0;
    rateInfo = seasonalRate.rate;
  } else {
    rateInfo = rateHistory.rate;
  }

  const tax = rateHistory.meta.custom_tax ?? getSupplierTax(suppliers, product);
  return ProductService.calcTotal({
    rateInfo,
    tax,
    taxInclusive: rateHistory.tax_inclusive === 1,
    extraList: getExtraList(rateHistory),
    occupancy: getOccupancy(suppliers, product, rateHistory),
    resort_fee: getResortFee(suppliers, product),
  });
}

function getSupplierTax(suppliers, product) {
  const supplier = suppliers[product.supplier_id].supplier;
  return supplier ? supplier.meta.tax : 0;
}

// Get Occupancy
function getOccupancy(suppliers, product, rateHistory) {
  if (!(showOccupancy(suppliers, product) || customOccupancyIsVisible(product, rateHistory))) return null;
  return occupancy(suppliers, product);
}

function showOccupancy(suppliers, product) {
  return product.product_type === "ROOM" && occupancy(suppliers, product).value && !product.tax_inclusive;
}

function occupancy(suppliers, product) {
  const supplier = suppliers[product.supplier_id].supplier;
  return supplier ? supplier.meta.occupancy_rate || {} : {};
}

function customOccupancyIsVisible(product, rateHistory) {
  return showCustomOccupancy(product) && customOccupancy(product, rateHistory) !== false;
}

function customOccupancy(product, rateHistory) {
  return rateHistory.meta.custom_occupancy ?? false;
}

function showCustomOccupancy(product) {
  return product.product_type === "ROOM";
}

// Get Resort Fee
function getResortFee(suppliers, product) {
  return showResortFee(suppliers, product) ? resortFee(suppliers, product) : null;
}

function resortFee(suppliers, product) {
  const supplier = suppliers[product.supplier_id].supplier;
  return supplier ? supplier.meta.resort_fee || {} : {};
}

function showResortFee(suppliers, product) {
  return product.product_type === "ROOM" && resortFee(suppliers, product).value && !product.tax_inclusive;
}

// Get Extra List
function getExtraList(rateHistory) {
  return Object.keys(rateHistory.meta)
    .filter((key) => key.includes("extra_"))
    .map((key) => ({
      key,
      ...rateHistory.meta[key],
    }));
}

function exportOverviewToExcel(env, supplierProductData, data, perPax) {
  // Initialize accommodations array
  const accommodations = [];

  // Populate accommodations array
  for (const cost of [...data.variable_costs, ...data.fixed_costs]) {
    let supplierCity,
      supplierName,
      isRoom = false;

    if (cost.supplier_id != null && cost.product_id != null) {
      const product = supplierProductData[cost.supplier_id].products[cost.product_id];
      if (product.product_type == "ROOM") {
        const hotelClass = supplierProductData[cost.supplier_id].supplier.meta?.hotel_class
          ? `${"*".repeat(supplierProductData[cost.supplier_id].supplier.meta.hotel_class)}`
          : null;
        supplierCity = `${supplierProductData[cost.supplier_id].supplier?.city}`;
        supplierName = `${supplierProductData[cost.supplier_id].supplier?.name}${hotelClass ? ` ${hotelClass}` : ""}`;
        isRoom = true;
      }
    } else if (cost.product_id == null && cost.product_type == "ROOM") {
      if (cost.supplier_id != null) {
        const hotelClass = supplierProductData[cost.supplier_id].supplier.meta?.hotel_class
          ? `${"*".repeat(supplierProductData[cost.supplier_id].supplier.meta.hotel_class)}`
          : null;
        supplierCity = `${supplierProductData[cost.supplier_id].supplier?.city}`;
        supplierName = `${supplierProductData[cost.supplier_id].supplier?.name}${hotelClass ? ` ${hotelClass}` : ""}`;
      } else {
        supplierCity = cost.supplier_city;
        supplierName = cost.supplier_name;
      }
      isRoom = true;
    }

    if (isRoom) {
      const isAccommodationExisting = accommodations.some(
        (item) => item.city === supplierCity && item.name === supplierName
      );
      if (!isAccommodationExisting) {
        accommodations.push({ city: supplierCity, name: supplierName });
      }
    }
  }

  // Generate date strings
  const dateString1 = new Date().toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const startDate2 = new Date(data.quote.start_date.year, data.quote.start_date.month - 1);
  const dateString2 = startDate2.toLocaleDateString("fr-FR", { month: "long", year: "numeric" });

  const currency = env == "USA" ? "USD" : "CAD";

  const peopleRanges = data.quote.people_ranges != null ? JSON.parse(data.quote.people_ranges) : [];
  const totalCostPax = data.quote.total_cost_pax != null ? data.quote.total_cost_pax : [];
  const maxLength = peopleRanges.length > accommodations.length ? peopleRanges.length : accommodations.length;

  const perPaxs = [];
  for (let i = 0; i < maxLength; i++) {
    let percent = data.quote.is_fixed_margin ? data.quote.margin_percent : data.quote.margin_pax[i];
    let perPax = (totalCostPax[i] * percent) / 100 + totalCostPax[i];
    perPaxs.push(perPax);
  }

  // Construct rows for Excel
  const rows = [
    ["", "", "", "", "Cotation Groupe"],
    ["", "", "", "", "Client :", "", data.client.name],
    ["", "", "", "", "Programme :", "", data.quote.title],
    ["", "", "", "", "Référence :", "", data.quote.reference],
    ["", "", "", "", "Date Cotation :", "", dateString1],
    [],
    ["", "", "Participants :", ":", "TBD"],
    [],
    ["", "", "Dates :", ":", dateString2],
    [],
    [],
  ];

  const overviewIncludedItems = data.quote.overview_included ? data.quote.overview_included.split("\n") : [];
  const overviewExcludedItems = data.quote.overview_excluded ? data.quote.overview_excluded.split("\n") : [];

  rows.push(["Inclus"]);
  overviewIncludedItems.forEach((item) => rows.push([item]));
  rows.push([]);
  rows.push(["Non-inclus"]);
  overviewExcludedItems.forEach((item) => rows.push([item]));
  rows.push([]);
  rows.push(["Cotation - (Prix pp en $" + currency + ")", "", "", "", "Hébergements"]);

  const cotationRowNumber = rows.length;
  rows.push(["Devis non contractuel, sous réserve de disponibilités, hors convention/congrès et fêtes"]);

  const devisRowNumber = rows.length;
  rows.push([]);
  rows.push(["Bases de personnes", "", "Occupation Double", "", "Ville", "", "Hôtel Cat Comfort (ou similaire)"]);

  const row = rows.length + 1;
  for (let i = 0; i < maxLength; i++) {
    rows.push(["", "", "", "", "", "", ""]);
    if (peopleRanges.length > i) {
      rows[row - 1 + i][0] = peopleRanges[i].min + "-" + peopleRanges[i].max + " PAX";
      rows[row - 1 + i][2] = "$" + perPaxs[i].toFixed(0);
    }
    if (accommodations.length > i) {
      rows[row - 1 + i][4] = accommodations[i].city;
      rows[row - 1 + i][6] = accommodations[i].name;
    }
  }

  rows.push([]);
  rows.push(["Supplement"]);
  const supplementRowNumber = rows.length;

  let totalCost = 0;
  [...data.variable_costs, ...data.fixed_costs].forEach((cost) => {
    if (cost.product_type === "ROOM") {
      totalCost += Number(cost.product_cost.value.substring(1));
    }
  });

  const hotelPricePerPerson = totalCost / 2;
  const singleSupplement = totalCost / 2;
  const tripleSupplement = totalCost / 3;
  const quadSupplement = totalCost / 4;
  const highestMinPeopleRange = peopleRanges.length == 0 ? 0 : Math.max(...peopleRanges.map((range) => range.min));
  const childSupplement = (perPax - singleSupplement) * 0.95;

  rows.push(["Single", "", singleSupplement.toFixed(2)]);
  rows.push(["Triple", "", tripleSupplement.toFixed(2)]);
  rows.push(["Quad", "", quadSupplement.toFixed(2)]);
  rows.push(["Child", "", childSupplement.toFixed(2)]);
  rows.push([]);

  const overviewDisclaimerItems = data.quote.overview_disclaimer ? data.quote.overview_disclaimer.split("\n") : [];
  overviewDisclaimerItems.forEach((item) => rows.push([item]));

  const mergeCell = [
    "E1:G1",
    "C7:C8",
    "D7:D8",
    "E7:E8",
    "C9:C10",
    "D9:D10",
    "E9:E10",
    `A${cotationRowNumber}:C${cotationRowNumber}`,
    `E${cotationRowNumber}:G${cotationRowNumber}`,
    `A${devisRowNumber}:G${devisRowNumber}`,
    `A${supplementRowNumber}:C${supplementRowNumber}`,
  ];

  const fontStyles = {
    calibriFont12Black: { font: { name: "Calibri", size: 12, color: { argb: "FF000000" } } },
    calibriFont12BlackBoldItalic: {
      font: { name: "Calibri", size: 12, color: { argb: "FF000000" }, bold: true, italic: true },
    },
    avenirFont11Black: { font: { name: "Avenir", size: 11, color: { argb: "FF000000" } } },
    avenirFont11BlackItalic: { font: { name: "Avenir", size: 11, color: { argb: "FF000000" }, italic: true } },
    avenirFont12Black: { font: { name: "Avenir", size: 12, color: { argb: "FF000000" } } },
    avenirFont12White: { font: { name: "Avenir", size: 12, color: { argb: "FFFFFFFF" } } },
    avenirFont12Grey: { font: { name: "Avenir", size: 12, color: { argb: "FF7F7F7F" } } },
    avenirFont13Grey: { font: { name: "Avenir", size: 13, color: { argb: "FF7F7F7F" } } },
    avenirFont14White: { font: { name: "Avenir", size: 14, color: { argb: "FFFFFFFF" } } },
    avenirFont14Grey: { font: { name: "Avenir", size: 14, color: { argb: "FF7F7F7F" } } },
    avenirFont28Grey: { font: { name: "Avenir", size: 28, color: { argb: "FF7F7F7F" } } },
  };

  const backgroundStyles = {
    goldBackground: { fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFC9A362" } } },
    grey1Background: { fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFC6C6C6" } } },
    grey2Background: { fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FF7F7F7F" } } },
  };

  const alignmentStyle = {
    left: { alignment: { vertical: "middle", horizontal: "left" } },
    center: { alignment: { vertical: "middle", horizontal: "center" } },
    right: { alignment: { vertical: "middle", horizontal: "right" } },
  };

  const borderStyle = {
    top: {
      border: {
        top: { style: "thin", color: { argb: "FF000000" } },
        left: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      },
    },
    body: {
      border: {
        left: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      },
    },
    bottom: {
      border: {
        left: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FF000000" } },
      },
    },
  };

  const config = [];

  config.push(
    { row: 1, options: { height: 77, ...fontStyles.avenirFont28Grey, ...alignmentStyle.right } },
    { column: 1, options: { width: 30.32904149 } },
    { column: 2, options: { width: 1.573676681 } },
    { column: 3, options: { width: 30.32904149 } },
    { column: 4, options: { width: 2 } },
    { column: 5, options: { width: 29.18454936 } },
    { column: 6, options: { width: 2.575107296 } },
    { column: 7, options: { width: 35.19313305 } }
  );

  for (let row = 2; row <= 5; row++) {
    config.push(
      { row, cell: 5, options: { ...fontStyles.avenirFont13Grey, ...alignmentStyle.right } },
      {
        row,
        cell: 7,
        options: { ...fontStyles.avenirFont12White, ...alignmentStyle.center, ...backgroundStyles.goldBackground },
      }
    );
  }

  [7, 9].forEach((row) => {
    config.push(
      { row, cell: 3, options: { ...fontStyles.avenirFont14Grey, ...alignmentStyle.right } },
      { row, cell: 4, options: { ...fontStyles.avenirFont12Grey, ...alignmentStyle.center } },
      { row, cell: 5, options: { ...fontStyles.avenirFont11Black, ...alignmentStyle.left } }
    );
  });

  let line = 12;

  config.push({
    row: line,
    cell: 1,
    options: { ...fontStyles.avenirFont14White, ...backgroundStyles.grey1Background },
  });

  line++;
  overviewIncludedItems.forEach((item, i) => {
    config.push({ row: line + i, cell: 1, options: { ...fontStyles.avenirFont11Black } });
  });
  line += overviewIncludedItems.length + 1;

  config.push({
    row: line,
    cell: 1,
    options: { ...fontStyles.avenirFont14White, ...backgroundStyles.grey1Background },
  });

  line++;
  overviewExcludedItems.forEach((item, i) => {
    config.push({ row: line + i, cell: 1, options: { ...fontStyles.avenirFont11Black } });
  });

  line += overviewExcludedItems.length + 1;

  config.push(
    {
      row: line,
      cell: 1,
      options: { ...fontStyles.avenirFont14White, ...backgroundStyles.goldBackground, ...alignmentStyle.center },
    },
    {
      row: line,
      cell: 5,
      options: { ...fontStyles.avenirFont14White, ...backgroundStyles.grey2Background, ...alignmentStyle.center },
    }
  );

  line++;
  config.push({
    row: line,
    cell: 1,
    options: { ...fontStyles.calibriFont12BlackBoldItalic, ...alignmentStyle.center },
  });

  line += 2;

  config.push(
    { row: line, cell: 1, options: { ...fontStyles.avenirFont14Grey, ...alignmentStyle.center } },
    {
      row: line,
      cell: 3,
      options: {
        ...fontStyles.avenirFont14White,
        ...backgroundStyles.goldBackground,
        ...alignmentStyle.center,
        ...borderStyle.top,
      },
    },
    { row: line, cell: 5, options: { ...fontStyles.avenirFont14Grey, ...alignmentStyle.center } },
    {
      row: line,
      cell: 7,
      options: { ...fontStyles.avenirFont14White, ...backgroundStyles.grey2Background, ...alignmentStyle.center },
    }
  );

  line++;

  for (let i = 0; i < maxLength; i++) {
    if (peopleRanges.length > i) {
      config.push(
        { row: line + i, cell: 1, options: { ...fontStyles.avenirFont12Grey, ...alignmentStyle.center } },
        {
          row: line + i,
          cell: 3,
          options: {
            ...fontStyles.avenirFont12Black,
            ...alignmentStyle.center,
            ...(i == peopleRanges.length - 1 ? borderStyle.bottomLeftRight : borderStyle.leftRight),
          },
        }
      );
    }
    if (accommodations.length > i) {
      config.push(
        { row: line + i, cell: 5, options: { ...fontStyles.avenirFont12Black, ...alignmentStyle.center } },
        { row: line + i, cell: 7, options: { ...fontStyles.avenirFont12Black, ...alignmentStyle.left } }
      );
    }
  }

  line = line + maxLength + 1;

  config.push({
    row: line,
    cell: 1,
    options: { ...fontStyles.avenirFont14White, ...backgroundStyles.goldBackground, ...alignmentStyle.center },
  });
  line = line + 1;

  for (let i = 0; i < 4; i++) {
    config.push(
      { row: line, cell: 1, options: { ...fontStyles.avenirFont12Black, ...alignmentStyle.center } },
      { row: line, cell: 3, options: { ...fontStyles.avenirFont12Black, ...alignmentStyle.center } }
    );
    line++;
  }

  overviewDisclaimerItems.forEach((item, i) => {
    config.push({ row: line + i, cell: 1, options: { ...fontStyles.avenirFont11BlackItalic } });
  });

  const fileName = `Quote - ${data.quote.title}`;
  return ExportService({ fileName: fileName, rows, config, mergeCell });
}

function exportDetailToExcel(env, supplierProductData, data, pax, perPax) {
  const styles = {
    font: {
      avenirFont10BlackBold: { font: { name: "Avenir", size: 10, color: { argb: "FF000000" }, bold: true } },
      avenirBookFont10Black: { font: { name: "Avenir Book", size: 10, color: { argb: "FF000000" } } },
      avenirBookFont10BlackBold: { font: { name: "Avenir Book", size: 10, color: { argb: "FF000000" }, bold: true } },
      avenirBookFont10BlackItalic: {
        font: { name: "Avenir Book", size: 10, color: { argb: "FF000000" }, italic: true },
      },
      avenirBookFont11BlackBold: { font: { name: "Avenir Book", size: 11, color: { argb: "FF000000" }, bold: true } },
      avenirBookFont11BlackItalic: {
        font: { name: "Avenir Book", size: 11, color: { argb: "FF000000" }, italic: true },
      },
      avenirBookFont12Black: { font: { name: "Avenir Book", size: 12, color: { argb: "FF000000" } } },
      avenirBookFont12BlackBold: { font: { name: "Avenir Book", size: 12, color: { argb: "FF000000" }, bold: true } },
      avenirBookFont16Black: { font: { name: "Avenir Book", size: 16, color: { argb: "FF000000" } } },
      avenirBookFont14WhiteBold: { font: { name: "Avenir Book", size: 14, color: { argb: "FFFFFFFF" }, bold: true } },
      avenirBookFont16WhiteBold: { font: { name: "Avenir Book", size: 16, color: { argb: "FFFFFFFF" }, bold: true } },
    },
    alignment: {
      left: { alignment: { vertical: "middle", horizontal: "left" } },
      center: { alignment: { vertical: "middle", horizontal: "center" } },
      right: { alignment: { vertical: "middle", horizontal: "right" } },
    },
    fill: {
      goldBackground: { fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFC9A362" } } },
      blue1Background: { fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FF8DB4DB" } } },
      blue2Background: { fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFDAE5F3" } } },
      grey1Background: { fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFC6C6C6" } } },
      orangeBackground: { fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFFFBC00" } } },
    },
    border: {
      all: {
        border: {
          top: { style: "thin", color: { argb: "FF000000" } },
          left: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
        },
      },
      topLeftRight: {
        border: {
          top: { style: "thin", color: { argb: "FF000000" } },
          left: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
        },
      },
      left: {
        border: {
          left: { style: "thin", color: { argb: "FF000000" } },
        },
      },
      right: {
        border: {
          right: { style: "thin", color: { argb: "FF000000" } },
        },
      },
      leftRight: {
        border: {
          left: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
        },
      },
      bottomLeft: {
        border: {
          left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
        },
      },
      bottomRight: {
        border: {
          right: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
        },
      },
      bottomLeftRight: {
        border: {
          left: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
        },
      },
    },
  };

  const currentDate = new Date().toLocaleDateString("fr-FR", { year: "numeric", month: "long", day: "numeric" });

  const rows = [
    ["", "", "", "Soumission faite le :"],
    ["", "", "", currentDate],
    ["", "", "", ""],
    ["", "", "", data.quote.title],
    ["", "", "", ""],
    ["", "", "COUTS VARIABLES PAR ADULTE - BASE DOUBLE", ""],
    ["", "", "Prestation", "Prix de"],
    ["", "", "", "vente"],
    ["", "Jour", "", ""],
  ];

  const config = [
    { column: 1, options: { width: 9.74 } },
    { column: 2, options: { width: 12.07 } },
    { column: 3, options: { width: 36.81 } },
    { column: 4, options: { width: 36.07 } },
    { column: 5, options: { width: 15.45 } },
    { column: 6, options: { width: 14.8 } },
    { row: 1, cell: 4, options: { ...styles.font.avenirBookFont10Black, ...styles.alignment.right } },
    { row: 2, cell: 4, options: { ...styles.font.avenirBookFont10Black, ...styles.alignment.right } },
    { row: 4, cell: 4, options: { ...styles.font.avenirBookFont16Black, ...styles.alignment.right } },
    {
      row: 6,
      cell: 3,
      options: { ...styles.font.avenirBookFont16WhiteBold, ...styles.alignment.center, ...styles.fill.goldBackground },
    },
    {
      row: 7,
      cell: 3,
      options: { ...styles.font.avenirBookFont14WhiteBold, ...styles.alignment.center, ...styles.fill.blue1Background },
    },
    {
      row: 7,
      cell: 4,
      options: { ...styles.font.avenirBookFont14WhiteBold, ...styles.alignment.center, ...styles.fill.blue1Background },
    },
    {
      row: 8,
      cell: 4,
      options: { ...styles.font.avenirBookFont14WhiteBold, ...styles.alignment.center, ...styles.fill.blue1Background },
    },
    { row: 9, cell: 2, options: { ...styles.font.avenirFont10BlackBold, ...styles.alignment.center } },
  ];

  const mergeCells = ["C6:D6", "C7:C8"];

  let totalVariableCosts = 0;
  let previousDay = null;
  let startDate = null;

  const { day, month, year } = data?.quote?.start_date ?? {};
  if (day && month && year) {
    startDate = new Date(year, month - 1, day);
  }

  const sortedVariableCosts = data.variable_costs.sort((a, b) => a.day - b.day || a.order_in_day - b.order_in_day);
  sortedVariableCosts.forEach((cost) => {
    if (previousDay !== cost.day) {
      if (previousDay !== null) {
        rows.push(["", "", "", ""]);
        config.push(
          { row: rows.length, cell: 3, options: { ...styles.border.leftRight } },
          {
            row: rows.length,
            cell: 4,
            options: { ...styles.border.leftRight, ...styles.fill.blue2Background },
          }
        );
      }

      let dateString = "";
      if (startDate) {
        const adjustedDate = new Date(startDate);
        adjustedDate.setDate(startDate.getDate() + cost.day);
        dateString = adjustedDate.toLocaleDateString("fr-FR", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }

      rows.push(["", cost.day + 1, dateString, ""]);
      config.push(
        {
          row: rows.length,
          cell: 2,
          options: { ...styles.font.avenirFont10BlackBold, ...styles.alignment.center },
        },
        {
          row: rows.length,
          cell: 3,
          options: {
            ...styles.font.avenirBookFont10BlackBold,
            ...styles.alignment.center,
            ...styles.fill.grey1Background,
            ...styles.border.all,
          },
        },
        {
          row: rows.length,
          cell: 4,
          options: { ...styles.font.avenirBookFont10BlackBold, ...styles.alignment.center, ...styles.border.all },
        }
      );
      previousDay = cost.day;
    }

    const supplierName = cost.supplier_id ? supplierProductData[cost.supplier_id].supplier.name : cost.supplier_name;
    const productName = cost.product_id
      ? supplierProductData[cost.supplier_id].products[cost.product_id].name
      : cost.product_name;
    const productCost = Number(cost.product_cost.value.substring(1));

    rows.push(["", "", `${supplierName} / ${productName}`, productCost.toFixed(2)]);
    config.push(
      { row: rows.length, cell: 3, options: { ...styles.font.avenirBookFont10Black, ...styles.border.leftRight } },
      {
        row: rows.length,
        cell: 4,
        options: {
          ...styles.font.avenirBookFont10BlackItalic,
          ...styles.alignment.right,
          ...styles.border.leftRight,
          ...styles.fill.blue2Background,
        },
      }
    );

    totalVariableCosts += productCost;
  });

  rows.push(["", "", "", ""]);
  config.push(
    { row: rows.length, cell: 3, options: { ...styles.border.bottomLeftRight } },
    {
      row: rows.length,
      cell: 4,
      options: { ...styles.border.bottomLeftRight, ...styles.fill.blue2Background },
    }
  );

  rows.push(["", "", "Frais variables par personne", totalVariableCosts.toFixed(2)]);
  config.push(
    {
      row: rows.length,
      cell: 3,
      options: { ...styles.font.avenirBookFont11BlackBold, ...styles.alignment.right },
    },
    { row: rows.length, cell: 4, options: { ...styles.font.avenirBookFont10Black, ...styles.alignment.right } }
  );

  rows.push(["", "", "", ""], ["", "", "", ""], ["", "", "COUTS FIXES PAR PERSONNE", ""]);
  config.push({
    row: rows.length,
    cell: 3,
    options: { ...styles.font.avenirBookFont16WhiteBold, ...styles.alignment.center, ...styles.fill.goldBackground },
  });

  mergeCells.push(`C${rows.length}:D${rows.length}`);

  rows.push(["", "", "Prestation", "Prix de"]);
  config.push(
    {
      row: rows.length,
      cell: 3,
      options: { ...styles.font.avenirBookFont14WhiteBold, ...styles.alignment.center, ...styles.fill.blue1Background },
    },
    {
      row: rows.length,
      cell: 4,
      options: { ...styles.font.avenirBookFont14WhiteBold, ...styles.alignment.center, ...styles.fill.blue1Background },
    }
  );

  const benefitRowNo1 = rows.length;

  rows.push(["", "", "", "vente"]);
  config.push({
    row: rows.length,
    cell: 4,
    options: { ...styles.font.avenirBookFont14WhiteBold, ...styles.alignment.center, ...styles.fill.blue1Background },
  });

  const benefitRowNo2 = rows.length;

  mergeCells.push(`C${benefitRowNo1}:C${benefitRowNo2}`);
  rows.push(["", "", "", ""]);

  let totalFixedCosts = 0;

  data.fixed_costs.forEach((cost, index) => {
    const supplierName = cost.supplier_id ? supplierProductData[cost.supplier_id].supplier.name : cost.supplier_name;
    const productName = cost.product_id
      ? supplierProductData[cost.supplier_id].products[cost.product_id].name
      : cost.product_name;
    const productCost = Number(cost.product_cost.value.substring(1));
    const days = cost.days != null ? JSON.parse(cost.days) : [];
    const productCostWithNumberOfDay = productCost * days.length;
    const borderOptions = index === 0 ? styles.border.topLeftRight : styles.border.leftRight;

    rows.push(["", "", `${supplierName} / ${productName}`, productCostWithNumberOfDay.toFixed(2)]);
    config.push(
      { row: rows.length, cell: 3, options: { ...styles.font.avenirBookFont10Black, ...borderOptions } },
      {
        row: rows.length,
        cell: 4,
        options: {
          ...styles.font.avenirBookFont10BlackItalic,
          ...styles.fill.blue2Background,
          ...styles.alignment.right,
          ...borderOptions,
        },
      }
    );

    totalFixedCosts += productCostWithNumberOfDay;
  });

  rows.push(["", "", "", ""]);
  config.push(
    { row: rows.length, cell: 3, options: { ...styles.border.bottomLeftRight } },
    { row: rows.length, cell: 4, options: { ...styles.border.bottomLeftRight, ...styles.fill.blue2Background } }
  );

  rows.push(["", "", "Frais fixes pour tout le groupe", totalFixedCosts.toFixed(2)]);
  config.push(
    {
      row: rows.length,
      cell: 3,
      options: { ...styles.font.avenirBookFont11BlackBold, ...styles.alignment.right },
    },
    { row: rows.length, cell: 4, options: { ...styles.font.avenirBookFont10Black, ...styles.alignment.right } }
  );

  const currency = env == "USA" ? "USD" : "CAD";

  rows.push(["", "", "", ""], ["", "", `Prix en ${currency} par personne :`, ""]);
  config.push({
    row: rows.length,
    cell: 3,
    options: {
      ...styles.font.avenirBookFont12BlackBold,
      ...styles.alignment.center,
      ...styles.border.all,
      ...styles.fill.orangeBackground,
    },
  });

  mergeCells.push(`C${rows.length}:D${rows.length}`);

  rows.push(["", "", "", "Occup. double"]);
  config.push(
    {
      row: rows.length,
      cell: 3,
      options: { ...styles.border.left },
    },
    {
      row: rows.length,
      cell: 4,
      options: { ...styles.font.avenirBookFont12BlackBold, ...styles.alignment.center, ...styles.border.right },
    }
  );

  rows.push(["", "", `Base ${pax}`, perPax.toFixed(2)]);

  const commonOptions = {
    ...styles.font.avenirBookFont12Black,
    ...styles.alignment.center,
  };

  const leftBorderOption = styles.border.bottomLeft;
  const rightBorderOption = styles.border.bottomRight;

  config.push(
    {
      row: rows.length,
      cell: 3,
      options: {
        ...commonOptions,
        ...leftBorderOption,
      },
    },
    {
      row: rows.length,
      cell: 4,
      options: {
        ...commonOptions,
        ...rightBorderOption,
        ...styles.font.avenirBookFont12BlackBold,
      },
    }
  );

  rows.push(["", "", "", ""], ["", "", `Supplement`, ""]);
  config.push({
    row: rows.length,
    cell: 3,
    options: {
      ...styles.font.avenirBookFont12BlackBold,
      ...styles.alignment.center,
      ...styles.border.all,
      ...styles.fill.orangeBackground,
    },
  });
  mergeCells.push(`C${rows.length}:D${rows.length}`);

  let totalCost = 0;
  [...data.variable_costs, ...data.fixed_costs].forEach((cost) => {
    if (cost.product_type === "ROOM") {
      totalCost += Number(cost.product_cost.value.substring(1));
    }
  });

  const hotelPricePerPerson = totalCost / 2;
  const singleSupplement = totalCost / 2;
  const tripleSupplement = totalCost / 3;
  const quadSupplement = totalCost / 4;

  const peopleRanges = data.quote.people_ranges ? JSON.parse(data.quote.people_ranges) : [];
  const highestMinPeopleRange = peopleRanges.length == 0 ? 0 : Math.max(...peopleRanges.map((range) => range.min));
  const childSupplement = (perPax - singleSupplement) * 0.95;

  rows.push(["", "", "Single", singleSupplement.toFixed(2)]);
  config.push(
    {
      row: rows.length,
      cell: 3,
      options: { ...commonOptions, ...styles.border.left },
    },
    {
      row: rows.length,
      cell: 4,
      options: { ...styles.font.avenirBookFont12BlackBold, ...styles.alignment.center, ...styles.border.right },
    }
  );

  rows.push(["", "", "Triple", tripleSupplement.toFixed(2)]);
  config.push(
    {
      row: rows.length,
      cell: 3,
      options: { ...commonOptions, ...styles.border.left },
    },
    {
      row: rows.length,
      cell: 4,
      options: { ...styles.font.avenirBookFont12BlackBold, ...styles.alignment.center, ...styles.border.right },
    }
  );

  rows.push(["", "", "Quad", quadSupplement.toFixed(2)]);
  config.push(
    {
      row: rows.length,
      cell: 3,
      options: { ...commonOptions, ...styles.border.left },
    },
    {
      row: rows.length,
      cell: 4,
      options: { ...styles.font.avenirBookFont12BlackBold, ...styles.alignment.center, ...styles.border.right },
    }
  );

  rows.push(["", "", "Child", childSupplement.toFixed(2)]);
  config.push(
    {
      row: rows.length,
      cell: 3,
      options: { ...commonOptions, ...leftBorderOption },
    },
    {
      row: rows.length,
      cell: 4,
      options: { ...styles.font.avenirBookFont12BlackBold, ...styles.alignment.center, ...rightBorderOption },
    }
  );

  const fileName = `Quote - ${data.quote.title}`;

  return ExportService({
    firstRowHeight: 15,
    iconArea: "A1:B4",
    fileName,
    rows: rows,
    config: config,
    mergeCell: mergeCells,
  });
}

function getPerPaxByMinimumOfRange(data, index) {
  const percent = data.quote.is_fixed_margin === true ? data.quote.margin_percent : data.quote.margin_pax[index];
  const totalCost = data.quote.total_cost_pax;
  return (totalCost[index] * percent) / 100 + totalCost[index];
}

function getSupplierTypeByProductType(productType) {
  const supplierTypes = {
    ROOM: "HOTEL",
    MEAL: "RESTAURANT",
    EXCURSION: "EXCURSION",
    TOUR: "TOUR_GUIDE",
    TRANS: "TRANSPORT",
    EXPENSE: "PARK",
  };
  return supplierTypes[productType] || null;
}

function isInPeopleRangeByInput(peopleRanges, input) {
  if (input === null) return false;
  return peopleRanges.some((range) => input >= range.min && input <= range.max);
}

function getLowestMinRange(peopleRanges) {
  if (peopleRanges.length === 0) return 0;
  return Math.min(...peopleRanges.map((range) => range.min));
}

function _isInDateRange(item, startMonth, startDay, endMonth, endDay) {
  if (!(item.mstart && item.mend)) return true;

  // User-entered range
  let userStart = parseInt(`${startMonth}${_getDayString(startDay, true, startMonth)}`);
  let userEnd = parseInt(`${endMonth}${_getDayString(endDay, false, endMonth)}`);

  // Item range
  let itemStart = parseInt(`${item.mstart}${_getDayString(item.dstart, true, item.mstart)}`);
  let itemEnd = parseInt(`${item.mend}${_getDayString(item.dend, false, item.mend)}`);

  // Handle wrap-around dates (e.g., October to April)
  if (itemStart > itemEnd) {
    return userStart >= itemStart || userEnd >= itemStart || userStart <= itemEnd || userEnd <= itemEnd;
  }

  // Check overlapping ranges
  return (
    (userStart <= itemStart && itemStart <= userEnd) || // Item starts within user range
    (userStart <= itemEnd && itemEnd <= userEnd) || // Item ends within user range
    (itemStart <= userStart && userEnd <= itemEnd) // User range fully inside item range
  );
}

function _getDayString(day, isStart, month) {
  if (!day) return isStart ? "01" : MONTHS[month - 1].days; // Use first or last day of the month
  return day < 10 ? `0${day}` : `${day}`; // Ensure two-digit format
}

export default {
  generateUUID,
  getTotalCostByPax,
  getTotalCostByCustomPax,
  getTotalCost,
  exportOverviewToExcel,
  exportDetailToExcel,
  getSupplierTypeByProductType,
  isInPeopleRangeByInput,
  getLowestMinRange,
};
