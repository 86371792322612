import api from "./api";
import Vue from "vue";

const UPDATE_SEARCH = "UPDATE_SEARCH";
const APPLY_SEARCH = "APPLY_SEARCH";
const RESET_SEARCH = "RESET_SEARCH";
const SET_MASS_RETURN = "SET_MASS_RETURN";

const namespaced = true;

const today = new Date();
const todayYear = today.getUTCFullYear();
const cutoffDate = new Date(Date.UTC(todayYear, 0, 1)); // March 1st of this year in UTC
const year = today > cutoffDate ? todayYear : todayYear - 1;
const defaultDate = new Date(Date.UTC(year, 0, 1));

const state = {
  fetching: false,

  searchRecord: {
    status: {}, // Object
    general: {
      from: defaultDate,
      status: "1",
    }, // Object
  },

  bookings: [],
  contacts: {},

  appliedSearch: "",
  // tab: Which tab is "active"
  // data: search blob
};

const mutations = {
  UPDATE_SEARCH(state, { tab, data }) {
    Vue.set(state.searchRecord, tab, { ...data });
  },

  SET_MASS_RETURN(state, { bookings, contacts }) {
    state.bookings = bookings;
    state.contacts = contacts;
  },

  APPLY_SEARCH(state, { tab, data }) {
    state.appliedSearch = tab;
    Vue.set(state.searchRecord, tab, data);
  },

  RESET_SEARCH(state) {
    state.searchRecord = {
      status: {},
      general: {},
    };

    state.appliedSearch = "";
  },
};

const actions = {
  updateSearch({ commit }, { tab, data }) {
    commit(UPDATE_SEARCH, { tab, data });
  },
  applySearch({ commit }, { tab, data }) {
    commit(APPLY_SEARCH, { tab, data });
  },
  resetSearch({ commit }) {
    commit(RESET_SEARCH);
  },

  getMassBookingContacts({ commit }, query) {
    return api
      .getMassBookingContacts(query)
      .then((data) => {
        commit(SET_MASS_RETURN, data.data);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  // Search Tab data
  generalSearch: (state) => state.searchRecord.general,
  statusSearch: (state) => state.searchRecord.status,
  // Applied Search stuff
  activeTab: (state) => state.appliedSearch,

  // Api Data
  bookings: (state) => state.bookings,
  contacts: (state) => state.contacts,
};

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
