<template>
  <LabelWrapper :label="label" :widelabel="widelabel" :smlabel="smlabel">
    <FText :disabled="disabled" :value="stringValue" @keydown="(v) => onKeydown(v)" @input="(v) => $emit('input', v)" />
  </LabelWrapper>
</template>

<script>
import LabelWrapper from "./wrappers/LabelWrapper";
import FText from "./items/FText";
export default {
  props: {
    disabled: Boolean,
    widelabel: Boolean,
    smlabel: Boolean,
    label: String,
    value: [String, Number],
    alphanumericOnly: Boolean,
  },
  components: {
    LabelWrapper,
    FText,
  },
  computed: {
    stringValue() {
      if (!this.value) return "";
      return `${this.value}`;
    },
  },
  methods: {
    onKeydown(event) {
      if (this.alphanumericOnly && this.isAlphanumericPattern(event.key)) {
        event.preventDefault();
      } else {
        this.$emit("keydown", event);
      }
    },
    isAlphanumericPattern(key) {
      return /[^a-zA-Z0-9]/.test(key);
    },
  },
};
</script>
