<template>
  <v-container class="pa-2" fluid>
    <v-row align="start" justify="center">
      <v-col cols="8">
        <d-header label="My Account" />

        <a-card>
          <div class="pa-2">
            <v-icon small left>account_box</v-icon>
            <span class="subtitle-2">Settings</span>
          </div>

          <v-divider />

          <elwrapper label="Role" widelabel>
            {{ displayRole }}
          </elwrapper>

          <AccountEditField
            label="Name"
            nopass
            :displayValue="displayName"
            :disabled="editing"
            :savefn="saveName"
            @clear="editing = false"
            @onedit="editing = true"
          />

          <AccountEditField
            confirm
            label="Login Email"
            :displayValue="displayEmail"
            :disabled="editing"
            :savefn="saveEmail"
            @clear="editing = false"
            @onedit="editing = true"
          />

          <AccountEditField
            password
            confirm
            label="Password"
            editLabel="New Password"
            displayValue="********"
            :disabled="editing"
            :savefn="savePassword"
            @clear="editing = false"
            @onedit="editing = true"
          />

          <elwrapper v-if="isHiddenReminderEnabled" label="Reminder" widelabel>
            <input class="mx-2" type="checkbox" :checked="hiddenReminders" @change="toggleActive(!hiddenReminders)" />
            <span>Hide cancellation and rooming list reminders</span>
          </elwrapper>

          <!-- <AccountEditField
					label="Signature"
					nopass
					editor
					:disabled="editing"
					:savefn="saveSignature"
					@clear="editing = false"
					@onedit="editing = true">
					<div v-html="displaySignature"></div>
				</AccountEditField> -->
        </a-card>

        <d-header label="Linked Webmail" />

        <EmailAccountBlock
          :accountId="accountId"
          :editing="editing"
          :linkedEmailList="linkedEmailList"
          @sync="postEmailAlter"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AccountEditField, EmailAccountBlock } from "@/modules/account";
export default {
  components: {
    AccountEditField,
    EmailAccountBlock,
  },
  data() {
    return {
      showEdit: false,
      name: "",
      email: "",
      password: "",
      editing: false,
    };
  },
  computed: {
    accountId() {
      return this.$store.getters["AccountStore/id"];
    },
    displayName() {
      return this.$store.getters["AccountStore/name"];
    },
    displayEmail() {
      return this.$store.getters["AccountStore/email"];
    },
    displayRole() {
      return this.$store.getters.role;
    },
    linkedEmailList() {
      return this.$store.getters["AccountStore/linkedEmailList"];
    },
    hiddenReminders() {
      return this.$store.getters["AccountStore/hidden_reminders"].length > 0;
    },
    isHiddenReminderEnabled() {
      return [
        "Regis.Novi@Supernature.travel",
        "Marc.Borremans@supernature.travel",
        "thibault@smartsoftasia.com",
      ].includes(this.displayEmail);
    },
  },
  methods: {
    saveName({ value }) {
      return this.$store
        .dispatch("AccountStore/updateAccount", {
          name: value,
        })
        .then(() => this.$root.$success("Updated Name"))
        .catch((err) => this.$root.$error(err));
    },
    // Requires password confirm
    saveEmail({ value, confirmPassword }) {
      return this.$store
        .dispatch("AccountStore/changeEmail", {
          new_email: value,
          password: confirmPassword,
        })
        .then(() => this.$root.$success("Updated Email"))
        .catch((err) => this.$root.$error(err));
    },
    savePassword({ value, confirmPassword }) {
      return this.$store
        .dispatch("AccountStore/changePassword", {
          new_password: value,
          old_pasword: confirmPassword,
        })
        .then(() => this.$root.$success("Updated Password"))
        .catch((err) => this.$root.$error(err));
    },
    postEmailAlter(data) {
      this.$store.dispatch("AccountStore/getPersonalEmailList");
    },
    toggleActive(isChecked) {
      const reminders = isChecked ? ["Cancellation Deadline", "Send Rooming List"] : [];
      this.$store.dispatch("AccountStore/updateAccount", {
        account_id: this.accountId,
        hidden_reminders: JSON.stringify(reminders),
      });
    },
  },
  mounted() {
    // Get Emails
    this.$store.dispatch("AccountStore/getPersonalEmailList");
  },
};
</script>
