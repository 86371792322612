<template>
  <div class="d-flex">
    <a-card nopad class="caption mx-0 px-0 flex-grow-1">
      <InvoiceBlock
        class="invoice-row"
        style="min-height: 30px; max-height: 30px"
        :hasInvoice="!!client_invoice.id"
        :partPaidInvoice="partPaidInvoice"
        :totalInvoice="totalInvoice"
        :sentDate="client_invoice.sent_date"
        :showInvoiceHistory="showInvoiceHistory"
        @toggle-invoice-history="showInvoiceHistory = !showInvoiceHistory"
        :showHistory="showHistory"
        @toggle-history="showHistory = !showHistory"
        @open-invoice-dialog="invoiceDialog = true"
        @open-part-dialog="partPaidDialog = true"
      />

      <v-row class="invoice-row caption" style="min-height: 30px">
        <v-col class="pa-0">
          <InvoiceHistory v-if="showInvoiceHistory" configAs="INVOICE_HISTORY" style="max-width: 600px" />
          <EstInvoiceBlock :bookingId="booking_id" />
        </v-col>
        <v-col>
          <InvoiceHistory v-if="showHistory" configAs="PAYMENT_HISTORY" style="max-width: 600px" />
        </v-col>
      </v-row>

      <!-- <EstInvoiceBlock /> -->
    </a-card>

    <MarginCard
      :billedTotal="billedTotal"
      :estBilledTotal="estBilledTotal"
      :estCommissionTotal="estCommissionTotal"
      :receivedCommissionTotal="receivedCommissionTotal"
      :totalInvoice="totalInvoice"
      :estInvoice="estInvoice"
      :partPaidInvoice="partPaidInvoice"
      :fullClientInvoiceSet="fullClientInvoiceSet"
    />

    <SetInvoiceDialog v-model="invoiceDialog" :totalInvoice="totalInvoice" @update="updateClientInvoice" />

    <SetPaidDialog
      v-model="partPaidDialog"
      :totalInvoice="totalInvoice"
      :paidAmount="partPaidInvoice"
      :bookingId="booking_id"
      :bookingData="bookingData"
    />
  </div>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";

import SetInvoiceDialog from "./invoice/dialogs/_SetInvoiceDialog";
import SetPaidDialog from "./invoice/dialogs/_SetPaidDialog";
import InvoiceBlock from "./invoice/_InvoiceBlock";
import InvoiceHistory from "./invoice/_InvoiceHistory";
import EstInvoiceBlock from "./invoice/EstInvoiceBlock";
import MarginCard from "./invoice/_MarginCard";

export default {
  props: {
    billedTotal: Number,
    estBilledTotal: Number,
    estCommissionTotal: Number,
    commissionTotal: Number,
    receivedCommissionTotal: Number,
  },
  components: {
    SetInvoiceDialog,
    SetPaidDialog,
    InvoiceBlock,
    InvoiceHistory,
    EstInvoiceBlock,
    MarginCard,
  },
  data() {
    return {
      invoiceDialog: false,
      partPaidDialog: false,
      showInvoiceHistory: false,
      showHistory: false,
    };
  },
  computed: {
    booking_id() {
      return this.$store.getters["FinanceReportStore/booking_id"];
    },
    payment_history() {
      return this.$store.getters["FinanceReportStore/payment_history"];
    },
    client_invoice() {
      // This should ALWAYS return an object
      return this.$store.getters["FinanceReportStore/client_invoice"];
    },
    client_invoice_total() {
      return this.$store.getters["FinanceReportStore/client_invoice_total"];
    },

    estInvoice() {
      const v = this.$store.getters["FinanceReportStore/est_invoice"];
      return v ? Number(v.estimate_amount) : 0;
    },

    totalInvoice() {
      return this.client_invoice_total;
    },
    fullClientInvoiceSet() {
      if (!this.client_invoice) return false;
      return this.client_invoice.invoice_type === "FULL";
    },
    partPaidInvoice() {
      if (!this.payment_history.length) return 0;
      return this.payment_history.reduce((total, item) => {
        return total + item.paid_amount;
      }, 0);
    },
    bookingData() {
      return this.$store.getters["FinanceReportStore/bookingData"];
    },
  },
  methods: {
    updateClientInvoice(data) {
      this.invoiceDialog = false;
      this._launchSave({
        invoice_type: data.invoiceType,
        total_invoice: data.invoiceValue,
        sent_date: data.invoiceDate,
        notes: data.notes,
      });
    },
    toggleInvoicePaid(value) {
      if (value === 2) {
        this.partPaidDialog = true;
        return Promise.resolve();
      }

      return this._launchSave({
        total_invoice: this.totalInvoice,
        paid: value,
      });
    },

    _launchSave(invoice) {
      return this.$store
        .dispatch("FinanceReportStore/updateClientInvoice", {
          booking_id: this.booking_id,
          invoice,
        })
        .catch((err) => {
          this.$root.$error("Failed to save client invoice");
        });
    },
  },
};
</script>

<style lang="scss">
.invoice-row {
  min-height: 30px;
  display: flex;
  .report-btn {
    display: none;
  }

  &:hover {
    .report-btn {
      display: inline;
    }
  }
}
</style>
