<template>
  <v-row class="pa-0 black--text">
    <v-col class="pa-0">
      <ProductPriceHover
        :altPrices="customPrices"
        :data="bs"
        :date="date"
        :typeAs="type"
        :pax="pax"
        :supcount="tourGuideTransportCount"
      >
        <v-col v-if="showRoomCount" align-self="center" class="d-flex ma-0 pa-0" style="min-width: 100px">
          <RoomNumberHover
            :dateList="[date]"
            :roomBreakdownData="roomBreakdownData"
            :bsmeta="bs.meta"
            :supplierCustomRoom="bs.meta && bs.meta.custom_room_num"
          />
        </v-col>
      </ProductPriceHover>
    </v-col>
    <v-col cols="2" v-if="showTime" class="d-flex pa-0" align-self="center">
      <v-icon small left>timer</v-icon>
      <span>{{ formatTime }}</span>
    </v-col>
    <v-col class="pa-0" style="max-width: 80px">
      <v-btn text small @click="$emit('edit', bs.id)">
        <v-icon x-small>edit</v-icon>
        <span>edit</span>
      </v-btn>
    </v-col>
    <v-col class="pa-0" style="max-width: 30px">
      <v-btn icon x-small @click="$emit('clear', bs)">
        <v-icon>clear</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import format from "@/services/format";
// import RoomNumberHover from '@/modules/bookings.rooms/components/roomNumberHover/RoomNumberHover'
import { BookingRoomService, RoomNumberHover } from "@/modules/bookings.rooms";
import { ProductPriceHover, BookingDayService } from "@/modules/bookings.days";
import bookingsDaysUtils from "@/modules/bookings.days/components/utils";
export default {
  props: {
    bs: Object,
    date: String,
    type: String,
  },
  components: {
    ProductPriceHover,
    RoomNumberHover,
  },
  computed: {
    productNames() {
      const bs = this.bs;
      return Object.values(bs.products)
        .map((v) => {
          let name = bs.meta && bs.meta.custom_names && bs.meta.custom_names[v.id];
          return name || v.name;
        })
        .join(", ");
    },
    customPrices() {
      if (!this.bs.meta) return {};
      return { ...this.bs.meta.custom_prices } || {};
    },

    showTime() {
      // Time option for everything except hotel
      return !["HOTEL"].includes(this.type) && this.bs.time;
    },
    formatTime() {
      const time = this.bs.time;
      if (!time) return "";
      return format.formatTime(time);
    },
    showRoomCount() {
      return this.type === "HOTEL" && !bookingsDaysUtils.isProductRateTypeGroup(this.bs.products);
    },
    pax() {
      // Get CURRENT Pax
      return this.$store.getters["BookingStore/pax_num"];
    },
    roomBreakdownData() {
      return BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.$store.getters["BookingStore/data"],
        bookingRoomingList: this.$store.getters["BookingRoomStore/rooms"],
        bookingDays: this.$store.getters["BookingDayStore/days"],
        supplierRoomList: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
      });
    },

    showExtraExcursion() {
      // In Canada Environment
      const env = this.$store.getters["AccountStore/environment"];
      return this.type === "EXCURSION" && env === "CDA";
    },
    tourGuideTransportCount() {
      if (!(this.type === "RESTAURANT" || this.showExtraExcursion)) return {};

      return BookingDayService.getIncludedTourGuideTransportMealCount(
        this.$store.getters["BookingDayStore/dayByDate"](this.date),
        this.$store.getters["BookingDayStore/suppliers"],
        this.bs.meta && this.bs.meta.exclude_suppliers && this.bs.meta.exclude_suppliers[this.date],
        {
          split: true,
          notransport: this.showExtraExcursion,
        }
      );
    },
  },
};
</script>
