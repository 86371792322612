<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" class="upload-dialog" persistent max-width="600px">
    <v-card v-if="uploadInProgress">
      <v-col align="center" justify="center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <div class="headline ma-3">Uploading: {{ fileName.join(", ") }}</div>
      </v-col>
    </v-card>

    <v-card v-else>
      <v-toolbar depressed flat>
        <span class="headline">{{ isimg ? "Upload Image" : "Upload Document" }}</span>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <v-file-input
          outlined
          dense
          hide-details
          show-size
          multiple
          :accept="isimg ? 'image/*' : documentAccepts"
          label="Files"
          :prepend-icon="displayIcon"
          @change="setFileList"
        />

        <div class="caption text-right" :class="{ 'red--text': errorAlert }">
          {{ isimg ? "Size limit: 1MB" : "Allowed: PDF, Docs, Excel, etc." }}
        </div>

        <v-divider />

        <div>
          <v-row v-for="(file, index) in selectedFileList" :key="file.path">
            <v-col class="preview" cols="5">
              <img v-if="isimg" :src="file.path" style="max-width: 100%" />
              <div v-else>
                <span>{{ file.name }}</span>
              </div>
            </v-col>
            <v-col cols="6">
              <a-text-field :label="isimg ? 'Label' : 'Name'" v-model="fileName[index]" :alphanumericOnly="true" />

              <div v-show="validSize(file)" class="caption red--text">
                <v-icon small left color="red">warning</v-icon>
                <span>Too big. Please upload smaller image.</span>
              </div>
            </v-col>
            <v-col cols="1">
              <v-btn icon @click="popImage(index)">
                <v-icon>clear</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button @onclick="$emit('input', false)" label="Cancel" />
        <g-button :disabled="disabled" type="primary" @onclick="upload" label="Upload" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isimg: Boolean,
    uploadInProgress: Boolean,
  },
  data() {
    return {
      fileName: [],
      selectedFileList: [],
    };
  },
  computed: {
    disabled() {
      if (!this.selectedFileList.length) return true;
      if (this.isimg) return !!this.selectedFileList.find((item) => item.size > 1000000);
      return false;
    },
    errorAlert() {
      if (this.isimg && this.selectedFileList.length)
        return !!this.selectedFileList.find((item) => item.size > 1000000);
      return false;
    },
    displayIcon() {
      if (this.isimg) return "mdi-camera";
      return "insert_drive_file";
    },
    documentAccepts() {
      return `.csv,.doc,.docx,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.pdf`;
    },
  },
  methods: {
    validSize(file) {
      if (!this.isimg) return false;
      return file.size > 1000000;
    },

    setFileList(fileList) {
      this.selectedFileList = fileList;
      this.fileName = fileList.map((item) => item.name.split(".")[0].replace(/[^a-zA-Z0-9 ]/g, ""));
    },
    popImage(index) {
      this.selectedFileList.splice(index, 1);
      this.fileName.splice(index, 1);
    },
    upload() {
      let submissions = this.selectedFileList.map((item, index) => {
        return {
          upload: item,
          [this.isimg ? "description" : "name"]: this.fileName[index],
        };
      });

      this.$emit("upload", submissions);
    },
  },
};
</script>

<style lang="scss">
.upload-dialog {
  .preview {
    img {
      max-width: 100%;
    }
  }
}
</style>
