<template>
  <v-col class="pa-0">
    <v-row justify="space-between">
      <delete-button
        quiet
        headline="Delete Product?"
        :disabled="productInBooking"
        disabledText="Product used in a booking. Cannot delete."
        :ondelete="deleteProduct"
      />

      <div class="d-flex">
        <ProductYearDropWithDialog
          :yearOptions="rateYearList"
          :showYear="displayYearRate"
          @setYearDisplay="(v) => (displayYearRate = v)"
          :addNextYear="addNextYear"
        />
        <g-button class="mx-2" label="Copy" icon="file_copy" @onclick="copyProduct" />
        <save-button :onsave="save" />
      </div>
    </v-row>

    <GeneralProductInfo :customYear="displayYearRate" :pid="productPID" />

    <a-card>
      <elwrapper label="Default Technical" widelabel texttop>
        <f-text v-model="default_technical" />
      </elwrapper>
      <elwrapper label="Technical Notes" widelabel texttop v-if="showTechnicalNotes">
        <f-textarea bullets v-bullets rows="3" v-model="technical_notes" />
      </elwrapper>
      <elwrapper label="Private Notes" widelabel texttop>
        <f-textarea bullets v-bullets rows="3" v-model="notes" />
      </elwrapper>
      <elwrapper label="Menu" widelabel texttop v-if="productType === 'MEAL'">
        <f-textarea bullets v-bullets rows="4" v-model="menu_notes" />
      </elwrapper>
    </a-card>
  </v-col>
</template>

<script>
import { GeneralProductInfo, ProductYearDropWithDialog } from "@/modules/products";

export default {
  components: {
    GeneralProductInfo,
    ProductYearDropWithDialog,
  },
  data() {
    return {
      productInBooking: false,
    };
  },
  computed: {
    productId() {
      return this.$store.getters["ProductStore/id"];
    },
    productPID() {
      return this.$store.getters["ProductStore/pid"];
    },
    supplierId() {
      return this.$store.getters["ProductStore/supplier_id"];
    },
    productType() {
      return this.$store.getters["ProductStore/type"];
    },

    displayYearRate: {
      get() {
        return this.$store.getters["workingYear"];
      },
      set(v) {
        this.$store.dispatch("setDefaultProductYear", v);
      },
    },

    default_technical: {
      get() {
        return this.$store.getters["ProductStore/default_technical"];
      },
      set(nuVal) {
        return this.$store.dispatch("ProductStore/setUpdate", { key: "default_technical", val: nuVal });
      },
    },
    notes: {
      get() {
        return this.$store.getters["ProductStore/notes"];
      },
      set(nuVal) {
        return this.$store.dispatch("ProductStore/setUpdate", { key: "notes", val: nuVal });
      },
    },
    technical_notes: {
      get() {
        return this.$store.getters["ProductStore/technical_notes"];
      },
      set(nuVal) {
        return this.$store.dispatch("ProductStore/setUpdate", { key: "technical_notes", val: nuVal });
      },
    },
    menu_notes: {
      get() {
        return this.$store.getters["ProductStore/menu_notes"];
      },
      set(nuVal) {
        return this.$store.dispatch("ProductStore/setUpdate", { key: "menu_notes", val: nuVal });
      },
    },

    rateYearList() {
      // Want:
      // All past product yeas
      return this.$store.getters["ProductStore/rate_history"]
        .map((item) => item.rate_year)
        .sort((a, b) => {
          if (a < b) return 1;
          if (a > b) return -1;
          return 0;
        });
    },

    showTechnicalNotes() {
      const type = this.$store.getters["ProductStore/type"];
      return !["ROOM"].includes(type);
    },
  },
  methods: {
    deleteProduct() {
      const supplierId = this.supplierId;
      const name = this.$store.getters["ProductStore/name"];
      return this.$store
        .dispatch("ProductStore/deleteProduct")
        .then(() => {
          this.$root.$success(`Deleted ${name}`);
          this.$router.push({
            name: "suppliers_products",
            params: { id: supplierId },
          });
        })
        .catch((err) => this.$root.$error(err));
    },
    copyProduct() {
      const name = this.$store.getters["ProductStore/name"];

      this.$root.$loading.open("Copying product");
      this.$store
        .dispatch("ProductStore/copyOneProduct", this.productId)
        .then((nuid) => {
          // Updating route and getting new
          this.$router.push({
            name: "product_page",
            params: { id: nuid },
          });
          return this.$store.dispatch("ProductStore/getProduct", nuid);
        })
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Copied " + name);
        })
        .catch((err) => this.$root.$error(err));
    },
    save() {
      return this.saveInformation(false, this.displayYearRate);
    },
    saveInformation(blockRouteChange, displayYearRate) {
      const name = this.$store.getters["ProductStore/name"];
      return this.$store
        .dispatch("ProductStore/updateProduct")
        .then((_) => this.$store.dispatch("ProductStore/updateProductRateByYear", displayYearRate))
        .then(() => {
          this.$root.$success(`Updated ${name}`);

          if (blockRouteChange) return;
          this.$router.push({
            name: "suppliers_products",
            params: { id: this.supplierId },
          });
        })
        .catch((err) => this.$root.$error(err));
    },
    addNextYear(nextYear) {
      return this.$store.dispatch("ProductStore/updateProductYear", nextYear);
    },
  },
  mounted() {
    this.$store
      .dispatch("ProductStore/checkProductInBooking", this.productId || this.$route.params.id)
      .then((val) => {
        this.productInBooking = !!val.count;
      })
      .catch((err) => this.$root.$error(err));
  },
  beforeDestroy() {
    // Save on tab change
    if (this.$store.getters["ProductStore/hasChanges"]) {
      this.saveInformation(true);
    }
  },
};
</script>
