/**
 * Checks if the rate type of the first product in the given products object is "PG".
 * Returns false if the products object is empty.
 *
 * @param {Object} products - The products object containing product details.
 * @returns {boolean} - True if the first product's rate type is "PG", otherwise false.
 */
function isProductRateTypeGroup(products) {
  const productKeys = Object.keys(products);

  if (productKeys.length === 0) {
    return false;
  }

  const firstProductKey = productKeys[0];
  const productRateType = products[firstProductKey]?.rate?.type;
  return productRateType === "PG";
}

export default {
  isProductRateTypeGroup,
};
