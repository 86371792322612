<template>
  <div>
    <v-row justify="space-between">
      <h3 class="title white--text">New Account</h3>
      <g-button label="Cancel" @onclick="$router.push({ name: 'admin' })" />
    </v-row>

    <a-card>
      <div>
        <a-text-field widelabel label="Display Name" v-model="name" />

        <a-select
          widelabel
          :label="(role === 'ADMIN' ? '' : 'Start ') + 'Environment'"
          :items="environments"
          v-model="env"
        />
        <a-select widelabel label="Role" :items="roles" v-model="role" />
        <CustomRoleManager v-if="role === 'ACCESS'" v-model="custom_access" />

        <a-text-field widelabel label="Login Email" v-model="email" />
        <elwrapper label="Login Password" widelabel texttop>
          <f-password v-model="email_password" />
        </elwrapper>
      </div>
    </a-card>

    <v-row justify="end" class="ma-3">
      <save-button label="Create" :disabled="!canSave" :onsave="saveAccount" />
    </v-row>
  </div>
</template>

<script>
import ENV from "@/config/ENV";
import { CustomRoleManager } from "@/modules/admin";
// import {NewGuideStepOne, NewGuideStepTwo} from '@/modules/admin'
export default {
  components: {
    // NewGuideStepOne,
    // NewGuideStepTwo,
    CustomRoleManager,
  },
  data() {
    var vm = this;
    return {
      roles: ["ACCESS", "ADMIN"],
      environments: ENV,
      custom_access: {},

      stepOn: 1,
      steps: 3,

      name: "",
      email: "",
      role: "ACCESS",
      env: "USA",
      email_password: null,
    };
  },
  computed: {
    canSave() {
      return this.name && this.email && this.role && this.env;
    },
  },
  methods: {
    saveAccount() {
      return this.$store
        .dispatch("AdminStore/createAccount", {
          name: this.name || "(No name added)",
          email: this.email,
          role: this.role,
          env: this.env,
          email_password: this.email_password,
          ...(this.role === "ACCESS" && { custom_access: this.custom_access }),
          hidden_reminders: [],
        })
        .then((v) => {
          this.$root.$success("Created account for: " + this.email);
          this.$router.push({ name: "admin" });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
