<template>
  <g-button type="error" v-show="show" :loading="loading" label="Sync All Counts" icon="sync" @onclick="massSync" />
</template>

<script>
import { BookingRoomService } from "@/modules/bookings.rooms";
import { BookingDayService } from "@/modules/bookings.days";
import bookingsDaysUtils from "@/modules/bookings.days/components/utils";

function setIntervalX(callback, delay, repetitions) {
  // Force sync by multiple checking,
  // Since not everything is automatically updating...
  var x = 0;
  var intervalID = window.setInterval(function () {
    callback();

    if (++x === repetitions) {
      window.clearInterval(intervalID);
    }
  }, delay);
}

export default {
  data() {
    return {
      show: false,
      loading: false,
    };
  },
  methods: {
    toggleShow() {
      this.show = true;
    },
    ping() {
      this.show = false;
      this.$syncCounts.$emit("ping");
    },
    massSync() {
      this.loading = true;
      // TO DO: Convert this blob to BookingDayService.syncConfirmedSuppliersInsideBooking
      const bookingData = this.$store.getters["BookingStore/data"];
      const roomingList = this.$store.getters["BookingRoomStore/rooms"];
      const suppliers = this.$store.getters["BookingDayStore/suppliers"];
      const roomBreakdownData = BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.$store.getters["BookingStore/data"],
        bookingRoomingList: this.$store.getters["BookingRoomStore/rooms"],
        bookingDays: this.$store.getters["BookingDayStore/days"],
        supplierRoomList: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
      });
      const dayinfo = this.$store.getters["BookingDayStore/daySupplierFlat"]("ALL");

      const paxNum = this.$store.getters["BookingRoomStore/totalPax"] ?? this.$store.getters["BookingStore/pax_num"];

      let preArray = [],
        postArray = [];
      let vm = this;
      Object.values(suppliers).forEach((bs) => {
        preArray.push(vm._presync(bs.type_as, bs, paxNum));
      });

      // Lineral to avoid scrambled data
      preArray
        .reduce(function (prev, curr) {
          return prev.then(curr);
        }, Promise.resolve())
        .then((_) => {
          Object.values(suppliers).forEach((bs) => {
            postArray.push(vm._postsync(bs, suppliers, roomBreakdownData, dayinfo, paxNum));
          });
          return postArray.reduce(function (prev, curr) {
            return prev.then(curr);
          }, Promise.resolve());
        })
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Synced supplier");
          setIntervalX(this.ping, 200, 5);
          this.loading = false;
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },

    _presync(typeAs, data, paxNum) {
      if (["RESTAURANT", "EXCURSION"].includes(typeAs) && !bookingsDaysUtils.isProductRateTypeGroup(data.products)) {
        // Sync the number of each supplier with people
        let metaref = data.meta || {};
        if (metaref.confirmed_offcount) return Promise.resolve();

        // Update the product count to reflect PAX (supplier count not needed here)
        return this.$store.dispatch("BookingDayStore/updateBookingSupplier", {
          bookingSupplierId: data.id,
          bookingId: this.$store.getters["BookingStore/booking_id"],
          data: {
            content: {
              meta: {
                ...metaref,
                count: Object.keys(data.products).reduce((map, key) => {
                  if (!(metaref.count_lock && metaref.count_lock[key])) {
                    map[key] = paxNum;
                  }
                  return map;
                }, {}),
              },
            },
          },
        });
      }

      return Promise.resolve();
    },
    _postsync(data, suppliers, roomBreakdownData, dayinfo, paxNum) {
      // Don't need to reset confirmed count + rooms, etc if still pending confirmation
      if (data.status < 4) return Promise.resolve();

      const overwrite = true;
      const vm = this;

      return this.$store.dispatch("BookingDayStore/updateOneSupplierStatus", {
        bookingSupplierId: data.id,
        bookingSuppliers: suppliers,
        bookingId: this.$store.getters["BookingStore/booking_id"],
        supplierId: data.supplier_id,
        applyAll: data.meta && data.meta.bound,

        data: {
          status: data.status, // Keep status same
          meta: data.meta,
        },
        getLockedPricesFn(item) {
          return BookingDayService.setLockedPrices({
            item,
            status: data.status,
            storeGetters: vm.$store.getters,
            useDates: null, // SKIP, don't need locked price
            overwrite,
          });
        },
        getConfirmedRoomDataFn(item, existingMeta) {
          return BookingDayService.setConfirmedRoomData({
            item,
            status: data.status,
            existingMeta,
            roomBreakdownData,
            dayinfo,
            overwrite,
          });
        },
        getConfirmedCountFn(item, existingMeta) {
          return BookingDayService.setConfirmedCount({
            env: vm.$store.getters["AccountStore/environment"],
            item,
            status: data.status,
            existingMeta,
            days: vm.$store.getters["BookingDayStore/days"],
            bookingSuppliers: vm.$store.getters["BookingDayStore/suppliers"],
            // bookingSupplierRoomList: vm.$store.getters['BookingSupplierRoomStore/supplier_rooms'],
            dayinfo,
            overwrite,
          });
        },
      });
    },
  },
  created() {
    setIntervalX(this.ping, 200, 5);
  },
  mounted() {
    this.$syncCounts.$on("show", this.toggleShow);
  },
  beforeDestroy() {
    this.$syncCounts.$off("show", this.toggleShow);
  },
};
</script>
