<template>
  <v-row class="justify-space-between caption" align="center">
    <v-col cols="4" align-self="center" class="pa-0">
      <v-row class="flex-nowrap">
        <v-col cols="7" class="pa-0" align-self="center">
          <a v-if="status" @click="goToSupplier" style="padding-top: 3px">
            <a-truncate bold :text="displayName" :hoverText="tooltipName" />
          </a>
          <a-truncate v-else bold :text="displayName" :hoverText="tooltipName" style="padding-top: 3px" />
        </v-col>
        <v-col cols="5" v-if="showCity" class="py-0 px-2" align-self="center">
          <a-truncate :text="city" />
        </v-col>
      </v-row>
    </v-col>

    <v-col class="pa-0">
      <v-row class="flex-nowrap" align="center">
        <v-col cols="1" class="py-0 px-1 d-flex justify-space-between">
          <div>
            <v-tooltip top v-if="hasCommission">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small color="grey lighten-1">monetization_on</v-icon>
              </template>
              <span>{{ commissionText }}: {{ hasCommission }} %</span>
            </v-tooltip>
          </div>

          <div>
            <v-tooltip top v-if="hasParking">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small color="grey lighten-1">local_parking</v-icon>
              </template>
              <div>
                <div v-if="hasParking.parking_vehicle_count">Vehicles: {{ hasParking.parking_vehicle_count }}</div>
                <div v-if="hasParking.parking_bus_count">Buses: {{ hasParking.parking_bus_count }}</div>
              </div>
            </v-tooltip>
          </div>
          <div>
            <v-tooltip top v-if="hasBreakfast">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small color="grey lighten-1">free_breakfast</v-icon>
              </template>
              <span>Breakfast Included</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="11" class="pa-0">
          <ProductPriceHover
            :altPrices="customPrices"
            :data="data"
            :date="date"
            :typeAs="type"
            :confirmed="data.status >= 4"
            :pax="pax"
            :offsync="outside"
            :supcount="tourGuideTransportCount"
            :showExtraExcursion="showExtraExcursion"
            @syncmeta="(v) => $emit('syncmeta', { ...v, data })"
          >
            <v-col v-if="showRoomCount" align-self="center" class="d-flex ma-0 pa-0" style="min-width: 100px">
              <RoomNumberHover
                :outside="outside"
                :confirmed="data.status >= 4"
                :dateList="[date]"
                :roomBreakdownData="roomBreakdownData"
                :bsmeta="data.meta"
                :supplierCustomRoom="data.meta && data.meta.custom_room_num"
                @syncmeta="
                  (v) => $emit('syncmeta', { key: v, data, bookingSupplierId: data.id, supplier_id: data.supplier_id })
                "
                @onSyncToggle="(v) => (syncToggle = v)"
              />
            </v-col>
          </ProductPriceHover>
        </v-col>
      </v-row>
    </v-col>

    <v-col style="max-width: 195px" align-self="center" class="pa-0">
      <v-row class="mx-3">
        <v-col style="max-width: 35px" class="pa-0">
          <RoomingReceivedToggle
            v-if="showRoomingReceivedToggle"
            :outside="outside"
            :bookingSupplierId="data.id"
            :roomingReceived="data.rooming_received"
            @toggleRoomReceived="$emit('toggleRoomReceived')"
          />
        </v-col>
        <v-col style="max-width: 35px" class="pa-0">
          <ConfirmNumber v-if="showConfirmNumber" :confirm="showConfirmNumber" @onclick="$emit('editConfirm')" />
        </v-col>
        <v-col style="max-width: 35px" class="pa-0">
          <SupplierRoom
            v-if="supplierRoomsOnDay.length"
            :roomingNameList="supplierRoomsOnDay"
            @onclick="$emit('editRoomName')"
          />
        </v-col>

        <v-row v-if="showTime" class="d-flex justify-end pa-0" align-self="center">
          <v-icon small left>timer</v-icon>
          <!-- {{formatTime}} -->
          <span style="width: 60px">{{ formatTime }}</span>
        </v-row>
      </v-row>
    </v-col>

    <v-col v-if="status" style="max-width: 50px" class="d-flex flex-row pa-0" align-self="center">
      <Progress
        :smstatus="smstatus"
        :levels="levelPath"
        :status="data.status"
        :prevstatus="data.prev_status"
        :prevdata="prevdata"
        :prevdays="previousDaysExtracted"
        :showPrevStatus="showPrevStatus"
        :syncToggle="syncToggle"
        @click.native="$emit('altstatus', data)"
      />

      <v-tooltip v-if="showReminder" top>
        <template v-slot:activator="{ on }">
          <div v-on="on" class="mx-2 d-flex flex-column justify-center">
            <v-icon small>notifications</v-icon>
            <span style="text-align: center">{{ reminderCount }}</span>
          </div>
        </template>
        <span>{{ lastReminderSent }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import format from "@/services/format";
import { BookingColors } from "@/config/fields/bookings";
// import RoomNumberHover from '@/modules/bookings.rooms/components/roomNumberHover/RoomNumberHover'
import { RoomNumberHover } from "@/modules/bookings.rooms";
import Progress from "./subs/_Progress";
import ProductPriceHover from "./subs/_ProductPriceHover";
import ConfirmNumber from "./subs/_ConfirmNumber";
import SupplierRoom from "./subs/_SupplierRoom";
import RoomingReceivedToggle from "./subs/_RoomingReceivedToggle";
import service from "../service";
import bookingsDaysUtils from "@/modules/bookings.days/components/utils";

export default {
  props: {
    data: Object,
    type: String,
    status: Boolean,
    smstatus: Boolean,
    outside: Boolean,

    date: String,

    bookingDay: Object, // Actual Day this product is in (via booking days)
    bookingSuppliers: Object, // Booking Supplier Mapping
    bookingSupplierRoomList: Array,

    bookingMeta: Object,
    prevdata: Object,
    prevdays: Array,
    roomBreakdownData: Object,
    pax: Number,
  },
  components: {
    Progress,
    RoomNumberHover,
    ProductPriceHover,
    ConfirmNumber,
    SupplierRoom,
    RoomingReceivedToggle,
  },
  data() {
    return {
      syncToggle: false,
    };
  },
  computed: {
    tooltipName() {
      let name = this.displayName;
      name = name.replace("*", "");
      if (this.data.supplier_env !== this.$store.getters["AccountStore/environment"]) {
        name = `${name} *(${this.data.supplier_env})`;
      }

      return name;
    },
    displayName() {
      if (this.data.supplier_meta && this.data.supplier_meta.en_name) {
        return this.data.supplier_meta.en_name;
      }

      return this.data.name;
    },

    showCity() {
      return ["HOTEL", "RESTAURANT", "EXCURSION"].includes(this.type);
    },
    city() {
      if (this.data.subcity || this.data.city) return `(${this.data.subcity || this.data.city})`;
      return "";
    },
    hasParking() {
      const meta = this.data.meta && this.data.meta.parking;
      if (!meta) return false;
      return meta;
    },
    hasBreakfast() {
      if (this.type !== "HOTEL") return;
      const meta = this.data.supplier_meta;
      if (meta && meta.breakfast && meta.breakfast.value === "included") return true;
      return false;
    },
    hasCommission() {
      if (this.type !== "HOTEL") return false;
      const bsmeta = this.data.meta;
      const meta = this.data.supplier_meta;
      if (bsmeta && bsmeta.has_custom_commission) {
        return `${bsmeta.custom_commission_value}`;
      } else if (meta && meta.commission && meta.commission.type === "PERCENT") {
        if (meta.commission.value === "undefined") return false;
        return `${meta.commission.value}`;
      }
      return false;
    },
    commissionText() {
      const bsmeta = this.data.meta;
      return bsmeta && bsmeta.has_custom_commission ? "Custom Commission" : "Commission";
    },

    showConfirmNumber() {
      const CONFIRM = 4;
      if (this.data.status >= CONFIRM) {
        const num = this.data.meta.confirm_number;
        if (!num) return false;
        return typeof num === "string" ? num : num.join(", ");
      }
      return false;
    },

    showRoomingReceivedToggle() {
      return this.type === "HOTEL";
    },

    /********
     * SUPPLIER ROOM
     * ******/
    supplierRoomsOnDay() {
      const dayToSupplierRoomsMapping = this.$store.getters["BookingDayStore/daySupplierRoomRef"];
      const supplierRoomMapping = this.$store.getters["BookingSupplierRoomStore/supplierRoomsRef"];

      if (!dayToSupplierRoomsMapping || !dayToSupplierRoomsMapping[this.date]) return [];
      return dayToSupplierRoomsMapping[this.date]
        .filter((sroomid) => {
          return supplierRoomMapping[sroomid].supplier_id === this.data.supplier_id;
        })
        .map((sroomid) => {
          return supplierRoomMapping[sroomid];
        });
    },

    showRoomCount() {
      return this.type === "HOTEL" && !bookingsDaysUtils.isProductRateTypeGroup(this.data.products);
    },

    showTime() {
      // Time option for everything except hotel
      return !["HOTEL"].includes(this.type) && this.data.time;
    },
    formatTime() {
      const time = this.data.time;
      if (!time) return "";
      return format.formatTime(time);
    },

    customPrices() {
      if (!this.data.meta) return {};
      return { ...this.data.meta.custom_prices } || {};
    },

    levelPath() {
      if (["HOTEL"].includes(this.type)) return 4;
      return 2;
    },

    showPrevStatus() {
      return this.$store.getters["BookingStore/suppliersNeedUpdate"] ? true : false;
    },

    showReminder() {
      return this.data.reminder && this.data.reminder.length;
    },
    reminderCount() {
      if (!this.data.reminder) return 0;
      return this.data.reminder.length;
    },
    lastReminderSent() {
      if (!this.data.reminder || this.data.reminder.length === 0) return "(None)";
      let lastDate = this.data.reminder[this.data.reminder.length - 1];
      return `Last Reminder: ${format.formatDate(lastDate.date)}`;
    },

    previousDaysExtracted() {
      if (!this.prevdays) return [];
      return this.prevdays
        .filter((item) => {
          return [
            ...item.hotels,
            ...item.restaurants,
            ...item.excursions,
            ...item.tour_guides,
            ...item.transport,
            ...item.parks,
          ].includes(this.data.id);
        })
        .map((item) => {
          return item.date;
        });
    },

    showExtraExcursion() {
      // In Canada Environment
      const env = this.$store.getters["AccountStore/environment"];
      // return this.type === "EXCURSION" && env === "CDA";
      return this.type === "EXCURSION" && !bookingsDaysUtils.isProductRateTypeGroup(this.data.products);
    },
    tourGuideTransportCount() {
      // if (!(this.type === "RESTAURANT" || this.showExtraExcursion)) return {};

      return service.getIncludedTourGuideTransportMealCount(
        this.bookingDay,
        this.bookingSuppliers,
        this.data.meta && this.data.meta.exclude_suppliers && this.data.meta.exclude_suppliers[this.date],
        {
          split: true,
          // notransport: this.showExtraExcursion
        }
      );
    },
  },
  methods: {
    goToSupplier() {
      // Go to selected supplier
      this.$router.push({
        name: "suppliers_info",
        params: {
          id: this.data.supplier_id,
        },
      });
    },
  },
};
</script>
